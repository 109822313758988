import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import '../css/Login.scss';
import '../css/colors.scss';
import { Button, Form, Row, Col, Card, Container } from 'react-bootstrap';
import geniallLogo from '../media/geniall-logo.png';
import api from '../api';

const Login = props => {

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        document.title = 'Iniciar Sesión';
    }, []);

    const _handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const body = {};
        formData.forEach((value, property) => body[property] = value);
        await api.login(body)
            .then(res => {
                if (res.data.success) {
                    const data = res.data;
                    localStorage.setItem('id', data.user);
                    localStorage.setItem('expert', data.isExpert);
                    localStorage.setItem('student', data.isStudent);
                    localStorage.setItem('company', data.isCompany);
                    props.history.push('/dashboard');                  
                }
            })
            .catch(error => {
                if (error.response !== undefined) {
                    alert(error.response.data.error + ":\n" + error.response.data.message);
                } else {
                    alert(error + "Error al conectarse al servidor");
                }
            })
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm={true} md={8} lg={5}>
                    <Card className="login-card">
                        <Card.Img src={geniallLogo} bsPrefix="geniall-logo" />
                        <Card.Body>
                            <Form onSubmit={_handleSubmit}>
                                <Form.Group controlId="email">
                                    <Form.Label className="text-primary-color">Correo electrónico</Form.Label>
                                    <Form.Control required type="email" placeholder="ejemplo@geniall.io" name="email" />
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <Form.Label className="text-primary-color">Contraseña</Form.Label>
                                    <Form.Control required type={showPassword ? "text" : "password"} name="password" />
                                </Form.Group>

                                <Form.Group controlId="toggleShowPassword">
                                    <Form.Check
                                        type="checkbox"
                                        onChange={_ => setShowPassword(!showPassword)}
                                        label="Mostrar contraseña" />
                                </Form.Group>

                                <Card.Link href="#">¿Olvidaste tu contraseña?</Card.Link>

                                <Button variant="primary-color" block className="login-btn" type="submit">Iniciar Sesión</Button>
                            </Form>
                            <Card.Text className="px-1 py-3">
                                ¿No tienes cuenta en Geniall? <Card.Link href="/registro">Regístrate aquí</Card.Link>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(Login);
