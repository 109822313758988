import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import '../../css/colors.scss';
import '../../css/ExpertRegister.scss';
import { Button, ButtonGroup, ToggleButton, Form, Row, Col, Card, Container } from 'react-bootstrap';
import geniallLogo from '../../media/geniall-logo.png';
import api from '../../api'
import { find } from 'lodash';

const ExpertRegister = props => {

    const [radioValue, setRadioValue] = useState('1');    
    const [showPassword, setShowPassword] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const cardMaxWidth = windowWidth >= 650 ? "90%" : "100%";

    useEffect(() => {
        document.title = 'Registro';
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    const _handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const body = {};
        formData.forEach((value, property) => {
            if (property === "expertType") {
                body[property] = find(expertTypes, obj => { return obj.value === value }).name;
            } else {
                body[property] = value
            }
        });
        await api.registerExpert(body)
            .then(res => {
                if (res.data.success) {
                    alert("Registro exitoso");
                    props.history.push('/login');
                }
            })
            .catch(error => {
                if (error.response !== undefined) {
                    alert(error.response.data.error);
                } else {
                    alert("Error al conectarse al servidor");
                }
            })
    }

    const expertTypes = [
        { name: 'Experto Digital', value: '1' },
        { name: 'Perfil Patrocinado', value: '2' }
    ];

    return (
        <Container className="px-1">
            <Row className="justify-content-center">
                <Col sm={true} md={9} lg={7}>
                    <Card
                        className="register-card-exp mb-1"
                        style={{
                            maxWidth: cardMaxWidth
                        }}>
                        <Card.Img src={geniallLogo} bsPrefix="geniall-logo" />
                        <Card.Body>
                            <Card.Text className="text-primary-color h4 text-center">Sé un experto en Geniall</Card.Text>
                            <Form onSubmit={_handleSubmit}>

                                <Form.Group controlId="firstName">
                                    <Form.Label className="text-primary-color">Nombre</Form.Label>
                                    <Form.Control required type="text" name="firstName" />
                                </Form.Group>

                                <Form.Group controlId="lastName">
                                    <Form.Label className="text-primary-color">Apellido</Form.Label>
                                    <Form.Control required type="text" name="lastName" />
                                </Form.Group>

                                <Form.Group controlId="email">
                                    <Form.Label className="text-primary-color">Correo electrónico</Form.Label>
                                    <Form.Control required type="email" placeholder="ejemplo@geniall.io" name="email" />
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <Form.Label className="text-primary-color">Contraseña</Form.Label>
                                    <Form.Control required type={showPassword ? "text" : "password"} name="password" />
                                </Form.Group>

                                <Form.Group controlId="toggleShowPassword">
                                    <Form.Check
                                        type="checkbox"
                                        onChange={_ => setShowPassword(!showPassword)}
                                        label="Mostrar contraseña" />
                                </Form.Group>                                

                                <Form.Label className="text-primary-color">Tipo de experto</Form.Label>
                                <Form.Row>
                                    <Form.Group controlId="expertType" >
                                        <ButtonGroup toggle>
                                            {expertTypes.map((type, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    type="radio"
                                                    variant="outline-primary-color"
                                                    name="expertType"
                                                    value={type.value}
                                                    checked={radioValue === type.value}
                                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                                >
                                                    {type.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </Form.Group>
                                </Form.Row>
                                <Button variant="primary-color" block type="submit" className="my-4">Regístrame</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(ExpertRegister);
