import React from 'react';
import '../css/colors.scss';
import '../css/GeniallNavbar.scss';
import { Navbar, Nav } from 'react-bootstrap';

const GeniallNavbar = () => {
    const geniallLogoURL = "https://geniall.io/wp-content/uploads/2020/04/geniall-logo.png";
    return (
        <Navbar className="fixed-top" collapseOnSelect expand="lg" bg="light" variant="light" >
            <Navbar.Brand href="https://geniall.io/">
                <img
                    src={geniallLogoURL}
                    width="183"
                    height="auto"
                    className="d-inline-block align-top"
                    alt="logo geniall"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="https://geniall.io/">Página Principal</Nav.Link>
                    <div className="dropdown-divider"></div>
                    <Nav.Link href="/registro">Regístrate</Nav.Link>
                    <div className="dropdown-divider"></div>
                    {!localStorage.getItem('id') ?
                        <Nav.Link href="/login">Inicia Sesión</Nav.Link>
                        :
                        <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default GeniallNavbar;