import React, { useEffect } from 'react';
import '../css/Register.scss';
import '../css/colors.scss';
import GeniallNavbar from './GeniallNavbar.js';
import { Row, Col, Card, Container, Dropdown, DropdownButton } from 'react-bootstrap';
import geniallLogo from '../media/geniall-logo.png';
import backgroundImage from '../media/trama-1024x649.png';
import Background from './Background.js';

const Register = _ => {

    useEffect(() => {
        document.title = 'Registro';
    }, []);

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm={true} md={8} lg={5}>
                    <Card className="register-card">
                        <Card.Img src={geniallLogo} bsPrefix="geniall-logo" />
                        <Card.Body className="text-center">
                            <Card.Text className="text-primary-color h4">Regístrate</Card.Text>
                            <DropdownButton className="dropdown-primary-color my-5" title="Regístrate como">
                                {/* <Dropdown.Item href="#/action-1">Empresa</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Usuario</Dropdown.Item> */}
                                <Dropdown.Item href="/registro/registro-experto">Experto</Dropdown.Item>
                            </DropdownButton>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Register;
