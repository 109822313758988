import React, { useState, useEffect, useRef } from 'react';
import '../../css/ExpertProfile.scss';
import '../../css/colors.scss';
import {
    AddEducation,
    DisplayEducation,
    AddAchievement,
    DisplayAchievements,
    EditBasicInfo,
    DisplayBasicInfo,
    EditTrajectory,
    AddCertification,
    DisplayCertifications,
    AddArticle,
    DisplayArticles,
    AddLanguage,
    DisplayLanguages,
    AddClient,
    DisplayClients,
    AddSocialMedia,
    DisplaySocialMedia,
    AddWorkExperience,
    DisplayWorkExperience
} from './profile_sections';
import { openInNewTab } from '../../util';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { Edit, AddCircleOutline } from '@material-ui/icons';

/*
Esta ok pero una vez que ingrese al dashboard deberá aparecer una leyenda que diga algo así

“Su perfil está incompleto. Debe completar su perfil para completar su alta como experto y tener visibilidad en la plataforma”

Perfil Abreviado
Nombre y apellido
Areas de expertise :  por ejemplo Experto en Marketing Digital (podrían ser etiquetas)
Brief de su trayectoria : X Cantidad de Caracteres 
Foto :
Profesor de la Certificación : por ejemplo Certificación en Marketing Digital (Una experto puede proponer una o mas certificaciones)

Podemos tener dos tipos de expertos

Expertos “geniales” (con al menos una certificación)
Consultores Digitales (sin certificaciones)

Esta categorización debe mostrarse en el dashboard del experto con alguna insignia y en los perfiles públicos  y debería incidir en las recomendaciones que la plataforma haga a los clientes que buscan expertos (ya sea a través del algoritmo o en forma manual). Cada vez que un experto suba de categoría podría generarse una notificación a los usuarios y otras acciones de marketing y marca personal.

Si es perfil patrocinado agregar

Logo de empresa
WEB de empresa
Info de contacto

NOTA : la idea es tener dos tipos de expertos. El experto habitual y el experto patrocinado. El experto patrocinado es aquel que se suma a la plataforma en nombre de su empresa para difundir sus productos. Este experto debe pagar para ingresar en esa categoría. Los datos que debe ingresar un experto patrocinado son los mismos aunque adicionalmente podrá ingresar datos de su empresa (lago de marca + pagina web, info de contacto) Entonces  

PANTALLA 5

El menú lo dividiria si y en este orden

PERFIL
se ve la foto del experto , un botón de EDITAR PERFIL
UN BOTON DE Preview de perfil (aquí la idea es que puedas ver en todo momento como se ve tu perfil publico , tal como lo van a ver los usuarios)
*/

const ExpertProfile = props => {

    const expert = props.expert;
    const expertFound = Object.keys(expert).length > 0;
    const [basicInfoEditModal, toggleBasicInfoEditModal] = useState(false);
    const [educationAddModal, toggleEducationAddModal] = useState(false);
    const [educationEditModal, toggleEducationEditModal] = useState(false);
    const [workExperienceAddModal, toggleWorkExperienceAddModal] = useState(false);
    const [workExperienceEditModal, toggleWorkExperienceEditModal] = useState(false);
    const [showSection3Window, toggleSection3Window] = useState(false);
    const [achievementAddModal, toggleAchievementAddModal] = useState(false);
    const [achievementEditModal, toggleAchievementEditModal] = useState(false);
    const [certificationAddModal, toggleCertificationAddModal] = useState(false);
    const [certificationEditModal, toggleCertificationEditModal] = useState(false);
    const [articleAddModal, toggleArticleAddModal] = useState(false);
    const [articleEditModal, toggleArticleEditModal] = useState(false);
    const [languageAddModal, toggleLanguageAddModal] = useState(false);
    const [languageEditModal, toggleLanguageEditModal] = useState(false);
    const [clientAddModal, toggleClientAddModal] = useState(false);
    const [clientEditModal, toggleClientEditModal] = useState(false);
    const [socialMediaAddModal, toggleSocialMediaAddModal] = useState(false);
    const [socialMediaEditModal, toggleSocialMediaEditModal] = useState(false);

    const prevCountRef = useRef(0);

    if (expert.settings !== undefined) {
        if (prevCountRef.current === 5 && expert.settings.profileSetupRequired) {
            alert("Perfil incompleto: Agregar Información Básica")
        }
        prevCountRef.current += 1;
    }

    useEffect(() => {
        if (props.expertUpdate > 4) {
            props.onExpertUpdate(1);
        } else {
            props.onExpertUpdate(props.expertUpdate + 1);
        }
    }, [
        basicInfoEditModal,
        educationAddModal,
        educationEditModal,
        workExperienceAddModal,
        workExperienceEditModal,
        showSection3Window,
        achievementAddModal,
        achievementEditModal,
        certificationAddModal,
        certificationEditModal,
        articleAddModal,
        articleEditModal,
        languageAddModal,
        languageEditModal,
        clientAddModal,
        clientEditModal,
        socialMediaAddModal,
        socialMediaEditModal
    ]);

    const ExpertCard = props => {
        const headerTitle = props.headerTitle ? props.headerTitle : "";
        const addOrEditIcon = props.addOrEditIcon ? props.addOrEditIcon : null;
        const titleAccess = props.titleAccess ? props.titleAccess : "";
        const toggleSection = props.toggleSection ? props.toggleSection : _ => { return null };
        return (
            <Card className="profile-section shadow-sm">
                <Card.Header>
                    <Row xs={2} className="justify-content-between mr-auto">
                        <Col className="text-break" xs={10}><h5>{headerTitle}</h5></Col>
                        <Col xs={1} className="text-right px-0">
                            {addOrEditIcon === "add" ?
                                (<AddCircleOutline
                                    titleAccess={titleAccess}
                                    className="primary-color pointer-cursor"
                                    onClick={_ => toggleSection(true)} />) :
                                addOrEditIcon === "edit" ?
                                    (<Edit
                                        titleAccess={titleAccess}
                                        className="primary-color pointer-cursor"
                                        onClick={_ => toggleSection(true)} />) :
                                    null
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {props.children}
                </Card.Body>
            </Card>);
    }

    return (
        <Col className="card-size mx-auto">
            <Row className="justify-content-center pb-4">
                <a onClick={()=>openInNewTab(`/experto/${expert?.userData?._id}`)} className="btn btn-primary-color">Ver Perfil Público</a>
            </Row>
            <Row className="justify-content-center">
                {basicInfoEditModal &&
                    <EditBasicInfo toggle={toggleBasicInfoEditModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Información Básica"}
                    addOrEditIcon={"edit"}
                    titleAccess={"Editar información"}
                    toggleSection={toggleBasicInfoEditModal}
                >
                    {expertFound && <DisplayBasicInfo expert={expert} />}
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {showSection3Window &&
                    <EditTrajectory toggle={toggleSection3Window} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"BIO Personal"}
                    addOrEditIcon={"edit"}
                    titleAccess={"Editar bio personal"}
                    toggleSection={toggleSection3Window}
                >
                    <p className="font-italic expert-textarea">{expert?.trajectoryFull}</p>
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {workExperienceAddModal &&
                    <AddWorkExperience toggle={toggleWorkExperienceAddModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Experiencia Laboral"}
                    addOrEditIcon={"add"}
                    titleAccess={"Añadir experiencia"}
                    toggleSection={toggleWorkExperienceAddModal}
                >
                    <DisplayWorkExperience expert={expert} toggle={toggleWorkExperienceEditModal} show={workExperienceEditModal} />
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {educationAddModal &&
                    <AddEducation toggle={toggleEducationAddModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Añadir educación"}
                    addOrEditIcon={"add"}
                    titleAccess={"Añadir educación"}
                    toggleSection={toggleEducationAddModal}
                >
                    <DisplayEducation expert={expert} toggle={toggleEducationEditModal} show={educationEditModal} />
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {achievementAddModal &&
                    <AddAchievement toggle={toggleAchievementAddModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Premios"}
                    addOrEditIcon={"add"}
                    titleAccess={"Añadir Premio"}
                    toggleSection={toggleAchievementAddModal}
                >
                    <DisplayAchievements expert={expert} toggle={toggleAchievementEditModal} show={achievementEditModal} />
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {certificationAddModal &&
                    <AddCertification toggle={toggleCertificationAddModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Certificaciones"}
                    addOrEditIcon={"add"}
                    titleAccess={"Añadir certificación"}
                    toggleSection={toggleCertificationAddModal}
                >
                    <DisplayCertifications expert={expert} toggle={toggleCertificationEditModal} show={certificationEditModal} />
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {articleAddModal &&
                    <AddArticle toggle={toggleArticleAddModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Artículos Escritos"}
                    addOrEditIcon={"add"}
                    titleAccess={"Añadir artículo"}
                    toggleSection={toggleArticleAddModal}
                >
                    <DisplayArticles expert={expert} toggle={toggleArticleEditModal} show={articleEditModal} />
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {languageAddModal &&
                    <AddLanguage toggle={toggleLanguageAddModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Idiomas"}
                    addOrEditIcon={"add"}
                    titleAccess={"Añadir idioma"}
                    toggleSection={toggleLanguageAddModal}
                >
                    <DisplayLanguages expert={expert} toggle={toggleLanguageEditModal} show={languageEditModal} />
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {clientAddModal &&
                    <AddClient toggle={toggleClientAddModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Clientes"}
                    addOrEditIcon={"add"}
                    titleAccess={"Añadir cliente"}
                    toggleSection={toggleClientAddModal}
                >
                    <DisplayClients expert={expert} toggle={toggleClientEditModal} show={clientEditModal} />
                </ExpertCard>
            </Row>

            <Row className="justify-content-center">
                {socialMediaAddModal &&
                    <AddSocialMedia toggle={toggleSocialMediaAddModal} expert={expert} />
                }
                <ExpertCard
                    headerTitle={"Redes Sociales"}
                    addOrEditIcon={"add"}
                    titleAccess={"Añadir red social"}
                    toggleSection={toggleSocialMediaAddModal}
                >
                    <DisplaySocialMedia expert={expert} toggle={toggleSocialMediaEditModal} show={socialMediaEditModal} />
                </ExpertCard>
            </Row>
        </Col>
    );
}

export default ExpertProfile;