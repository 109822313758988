import React, { useState, useEffect } from 'react';
import '../../../css/colors.scss';
import '../../../css/ExpertProfile.scss';
import ConfirmationDialog from '../../ConfirmationDialog';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import { cloneDeep, map, remove, forEach } from 'lodash';
import { Edit } from '@material-ui/icons';
import updateExpert from '../../../api/expert';

const ClientsModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const clients = expert?.clients;
    const isEditView = props.index !== undefined;
    const index = isEditView ? props.index : -1;

    const [name, setName] = useState(isEditView ? clients[index].name : "");
    const [webpage, setWebpage] = useState(isEditView ? clients[index].webpage : "");
    const [description, setDescription] = useState(isEditView ? clients[index].description : "");
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            if (isEditView) updateClients();
            else addClient();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => formData.append(key, JSON.stringify(value)))
        return formData;
    }

    const deleteClient = async _ => {
        remove(expert.clients, (_, i) => { return i === index; });
        await update();
    }

    const updateClients = async _ => {
        const clients = {
            name: name,
            webpage: webpage,
            description: description,
        }
        expert.clients[index] = clients;
        await update();
    }

    const addClient = async _ => {
        const clients = {
            name: name,
            webpage: webpage,
            description: description
        }
        expert.clients.push(clients);
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formName">
                                <Form.Label>Nombre del Cliente</Form.Label>
                                <Form.Control
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: Geniall, SocialMe, ..."
                                    name="name" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona el nombre de un cliente
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formWebpage">
                                <Form.Label>Página Web</Form.Label>
                                <Form.Control
                                    value={webpage}
                                    onChange={event => setWebpage(event.target.value)}
                                    type="text"
                                    placeholder="Ej: https://geniall.io ..."
                                    name="webpage" />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control
                                    value={description}
                                    onChange={event => setDescription(event.target.value)}
                                    as="textarea"
                                    rows="3"
                                    required
                                    name="description" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una descripción
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={!isEditView ? "justify-content-end" : "justify-content-between"}>
                        {isEditView &&
                            <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                                Borrar Cliente
                            </Button>
                        }
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar Cliente"
                    message="¿Realmente deseas borrar este cliente de tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteClient} />
            }
        </div>
    );
}

const AddClient = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <ClientsModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Añadir Cliente"} />
            </Row>
        </div>
    );
}

const EditClient = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <ClientsModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Cliente"}
                    index={props.index} />
            </Row>
        </div>
    );
}

const DisplayClients = props => {

    const expert = props.expert;
    const [clientIndex, setClientIndex] = useState(-1);

    useEffect(() => {
        props.toggle(true);
    }, [clientIndex]);

    const expertRows = map(expert?.clients, (client, index) => {
        const mb = index === expert.clients.length - 1 ? "" : "mb-3"
        const borderBottom = index === expert.clients.length - 1 ? "" : "border-bottom";
        return (
            <Row key={index} className={`${mb} ${borderBottom} mx-1`}>
                <Col xs={9}>
                    <p>
                        <span className="font-weight-bold card-headers">{client.name}</span><br />
                        {client.webpage && <span><a target="_blank" href={client.webpage}>{client.webpage}</a><br /></span>}
                        <span>{client.description}</span><br />
                    </p>
                </Col>
                <Col className="text-right" xs={3}>
                    <Edit
                        nameAccess={"Editar Cliente"}
                        className="primary-color pointer-cursor"
                        onClick={_ => setClientIndex(index)}
                    />
                </Col>
            </Row>
        );
    });

    return (
        <div>
            {props.show && clientIndex !== -1 &&
                <EditClient
                    toggle={props.toggle}
                    expert={expert}
                    index={clientIndex} />}
            {expertRows}
        </div>
    );
}

export { AddClient, EditClient, DisplayClients };

