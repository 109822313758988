import React, { useState, useEffect } from 'react';
import '../../../css/colors.scss';
import '../../../css/ExpertProfile.scss';
import ConfirmationDialog from '../../ConfirmationDialog';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import { cloneDeep, map, remove, forEach } from 'lodash';
import { Edit } from '@material-ui/icons';
import updateExpert from '../../../api/expert';
import Select from 'react-select'

const LanguagesModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const languages = expert?.languages;
    const isEditView = props.index !== undefined;
    const index = isEditView ? props.index : -1;

    const [name, setName] = useState(isEditView ? languages[index].name : "");
    const [level, setLevel] = useState(isEditView ? languages[index].level : "");
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);
    const [style, setStyle] = useState(
        {
            control: (base, state) => ({
                ...base,
                borderColor: state.isFocused ? 'rgb(128, 189, 255)' : 'hsl(0,0%,80%)',
                boxShadow: state.isFocused ? '0px 0px 1px 3px rgba(128, 189, 255, 0.5)' : 'none',
                "&:hover": {
                    borderColor: state.isFocused ? 'rgb(128, 189, 255)' : 'hsl(0,0%,80%)'
                }
            })
        }
    );

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        console.log(form);
        setValidated(true);
        setStyle(level === "" || level === undefined ? invalidStyle : validStyle);
        if (form.checkValidity() && level !== "") {
            if (isEditView) updateLanguages();
            else addLanguage();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => formData.append(key, JSON.stringify(value)))
        return formData;
    }

    const deleteLanguage = async _ => {
        remove(expert.languages, (_, i) => { return i === index; });
        await update();
    }

    const updateLanguages = async _ => {
        const languages = {
            name: name,
            level: level
        }
        expert.languages[index] = languages;
        await update();
    }

    const addLanguage = async _ => {
        const languages = {
            name: name,
            level: level
        }
        expert.languages.push(languages);
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    let invalidStyle = {
        control: (base, state) => ({
            ...base,
            borderColor: '#dc3545',
            boxShadow: state.isFocused ? '0px 0px 1px 3px rgba(220, 53, 69, 0.3)' : '#dc3545',
            "&:hover": {
                borderColor: '#dc3545'
            }
        })
    }

    let validStyle = {
        control: (base, state) => ({
            ...base,
            borderColor: '#28a745',
            boxShadow: state.isFocused ? '0px 0px 1px 3px rgba(40, 167, 69, 0.3)' : 'none',
            "&:hover": {
                borderColor: '#28a745'
            }
        })
    }

    const onChangeLevel = event => {
        setLevel(event?.value || "");
        if (validated) {
            setStyle(event?.value === "" || event?.value === undefined ? invalidStyle : validStyle);
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formLanguageName">
                                <Form.Label>Idioma</Form.Label>
                                <Form.Control
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: Inglés"
                                    name="name" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona un idioma
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formLevel">
                                <Form.Label>Nivel</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={
                                        { label: level, value: level }
                                    }
                                    isClearable
                                    isSearchable={false}
                                    onChange={onChangeLevel}
                                    name="color"
                                    options={[
                                        { label: "Básico", value: "Básico" },
                                        { label: "Intermedio", value: "Intermedio" },
                                        { label: "Avanzado", value: "Avanzado" },
                                    ]}
                                    styles={style}
                                />
                                {validated && level === "" &&
                                    < div class="invalid-feedback" style={{ display: "block" }}>Proporciona un nivel</div>
                                }
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={!isEditView ? "justify-content-end" : "justify-content-between"}>
                        {isEditView &&
                            <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                                Borrar Idioma
                            </Button>
                        }
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {
                confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar Idioma"
                    message="¿Realmente deseas borrar este idioma de tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteLanguage} />
            }
        </div >
    );
}

const AddLanguage = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <LanguagesModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Añadir Idioma"} />
            </Row>
        </div>
    );
}

const EditLanguage = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <LanguagesModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Idioma"}
                    index={props.index} />
            </Row>
        </div>
    );
}

const DisplayLanguages = props => {

    const expert = props.expert;
    const [languageIndex, setLanguageIndex] = useState(-1);

    useEffect(() => {
        props.toggle(true);
    }, [languageIndex]);

    const expertRows = map(expert?.languages, (language, index) => {
        const mb = index == expert.languages.length - 1 ? "" : "mb-3"
        const borderBottom = index == expert.languages.length - 1 ? "" : "border-bottom";
        return (
            <Row key={index} className={`${mb} ${borderBottom} mx-1`}>
                <Col xs={9}>
                    <p>
                        <span className="font-weight-bold card-headers">{language.name}</span><br />
                        <span>Nivel: {language.level}</span>
                    </p>
                </Col>
                <Col className="text-right" xs={3}>
                    <Edit
                        nameAccess={"Editar Idioma"}
                        className="primary-color pointer-cursor"
                        onClick={_ => setLanguageIndex(index)}
                    />
                </Col>
            </Row>
        );
    });

    return (
        <div>
            {props.show && languageIndex !== -1 &&
                <EditLanguage
                    toggle={props.toggle}
                    expert={expert}
                    index={languageIndex} />}
            {expertRows}
        </div>
    );
}

export { AddLanguage, EditLanguage, DisplayLanguages };

