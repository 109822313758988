import React, { useState, useEffect } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';

const SelectDashboard = props => {

    const [isExpert, setExpert] = useState(false);
    const [isStudent, setStudent] = useState(false);
    const [isCompany, setCompany] = useState(false);
    const [hasMultipleAccounts, setMultipleAccounts] = useState(false);


    useEffect(() => {
        checkIfLoggedIn();
        checkMultipleAcc();
        document.title = 'Seleccionar Cuenta';

    }, []);

    const checkIfLoggedIn = () => {
        const loggedUser = localStorage.getItem('logged');
        if (loggedUser !== null) {
            switch (loggedUser) {
                case "expert":
                    goToExpertDashboard();
                    break;
                case "student":
                    goToStudentDashboard();
                    break;
                case "company":
                    goToCompanyDashboard();
                    break;
            }
        }
    }

    const checkMultipleAcc = () => {
        const expert = localStorage.getItem('expert') === "true";
        const student = localStorage.getItem('student') === "true";
        const company = localStorage.getItem('company') === "true";
        setExpert(expert);
        setStudent(student);
        setCompany(company);
        const hasMA = (expert && student) || (expert && company) || (student && company);
        setMultipleAccounts(hasMA);
        if (!hasMA) {
            if (expert) {
                goToExpertDashboard();
            } else if (student) {
                goToStudentDashboard();
            } else {
                goToCompanyDashboard();
            }
        }
    }

    const goToExpertDashboard = () => {
        localStorage.setItem('logged', 'expert');
        props.history.push('/dashboard/experto');
    }

    const goToStudentDashboard = () => {
        localStorage.setItem('logged', 'student');
        props.history.push('/dashboard/estudiante');
    }

    const goToCompanyDashboard = () => {
        localStorage.setItem('logged', 'company');
        props.history.push('/dashboard/empresa');
    }

    return (
        <Container>
            <Modal show={hasMultipleAccounts && localStorage.getItem('logged') === null}>
                <Modal.Header>
                    <Modal.Title>Seleccionar Cuenta</Modal.Title>
                </Modal.Header>
                <Modal.Footer className="justify-content-center">
                    {isExpert &&
                        <Button variant="primary" onClick={goToExpertDashboard}>Experto</Button>
                    }
                    {isStudent &&
                        <Button variant="primary" onClick={goToStudentDashboard}>Estudiante</Button>
                    }
                    {isCompany &&
                        <Button variant="primary" onClick={goToCompanyDashboard}>Empresa</Button>
                    }
                </Modal.Footer>
            </Modal>
        </Container>
    );

}

export default SelectDashboard;