import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../DashboardNavbar.js';
import '../../css/ExpertDashboard.scss';
import '../../css/colors.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { ProSidebar, Menu, SubMenu, MenuItem } from 'react-pro-sidebar';
import {
    AccountCircle,
    Work,
    Call,
    Mail,
    InsertChart,
    Book,
    CalendarToday,
    VerifiedUser,
    Lock,
    ExitToApp
} from '@material-ui/icons';
import api from '../../api';
//import Certifications from './Certifications';

const StudentDashboard = props => {

    const EDIT_PROFILE = "EDIT_PROFILE";
    const VIEW_COURSES = "VIEW_COURSES";
    const SEARCH_COURSES = "SEARCH_COURSES";
    const ACCOUNT = "ACCOUNT";
    const LOGOUT = "LOGOUT";

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    /* const [expert, setExpert] = useState({});
    const [expertUpdate, onExpertUpdate] = useState(0); */
    const showAccountIcons = windowWidth <= 768;

    useEffect(() => {
        document.title = 'Dashboard Estudiantes';

        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    /* useEffect(_ => {
        loadExpert();
    }, [expertUpdate]);

    const loadExpert = async () => {

        await api.getExpert(localStorage.getItem('id'))
            .then(res => {
                if (res.data.success) {
                    const expert = res.data.expert;
                    setExpert(expert);
                }
            })
            .catch(error => {
                if (error.response !== undefined) {
                    alert(error.response.data.error);
                } else {
                    alert("Error al conectarse al servidor");
                }
            })
    } */

    const [mobileMenu, toggleMobileMenu] = useState(false);
    const [selectedOption, setSelectedOption] = useState(EDIT_PROFILE);

    const onClickMenuItem = option => {
        switch (option) {
            case LOGOUT:
                localStorage.clear();
                props.history.push('/');
                break;
            default:
                setSelectedOption(option);
                toggleMobileMenu(!mobileMenu);
                break;
        }
    }

    return (
        <Container fluid className="px-0">
            <DashboardNavbar
                type={"Estudiantes"}
                showMenu={mobileMenu}
                onClickMenu={toggleMobileMenu}
                onClickMenuItem={onClickMenuItem}
            />
            <Row className="all-content" noGutters>
                <Col xs="auto" className="sidebar-col">
                    <ProSidebar breakPoint="md" onToggle={_ => toggleMobileMenu(!mobileMenu)} toggled={mobileMenu}>
                        <Menu>
                            <SubMenu title="Perfil" className="sidebar-li" icon={<AccountCircle />}>
                                <MenuItem onClick={_ => onClickMenuItem(EDIT_PROFILE)}>
                                    Editar Perfil
                                </MenuItem>
                            </SubMenu>
                            <SubMenu title="Mis Cursos" className="sidebar-li" icon={<VerifiedUser />}>
                                <MenuItem onClick={_ => onClickMenuItem(VIEW_COURSES)}>
                                    Ver Cursos
                                </MenuItem>
                                <MenuItem onClick={_ => onClickMenuItem(SEARCH_COURSES)}>
                                    Buscar Cursos
                                </MenuItem>
                            </SubMenu>
                            {showAccountIcons ?
                                <MenuItem
                                    className="sidebar-li"
                                    icon={<Lock />}
                                    onClick={_ => onClickMenuItem(ACCOUNT)}>
                                    Mi Cuenta
                                </MenuItem> :
                                <div />
                            }
                            {showAccountIcons ?
                                <MenuItem
                                    className="sidebar-li"
                                    icon={<ExitToApp />}
                                    onClick={_ => onClickMenuItem(LOGOUT)}>
                                    Cerrar Sesión
                                </MenuItem> :
                                <div />
                            }
                        </Menu>
                    </ProSidebar>
                </Col>
                <Col className="main-content-dashboard">
                    <main>
                    </main>
                </Col>
            </Row>
        </Container>
    );

}

export default StudentDashboard;