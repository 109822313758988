import React from 'react';
import '../../css/colors.scss';
import { Col, Form, Button } from 'react-bootstrap';

const ExpertAccount = () => {
    return (
        <div>
            <Form>
				<Form.Row>
					<Form.Group as={Col} controlId="formUser">
						<Form.Label>Usuario</Form.Label>   
						<Form.Control type="text" />					
					</Form.Group>
				</Form.Row>

				<Form.Row>
					<Form.Group as={Col} controlId="formPsswd1">
						<Form.Label>Contraseña nueva</Form.Label> 
						<Form.Control type="password" />					
					</Form.Group>    
				</Form.Row>

				<Form.Row>
					<Form.Group as={Col} controlId="formPsswd2">
						<Form.Label>Confirmar contraseña nueva</Form.Label>    
						<Form.Control type="password" />						
					</Form.Group>
				</Form.Row>

                <Button variant="primary-color" type="submit">
                    Guardar cambios
				</Button>
            </Form>
        </div>
    );
}

export default ExpertAccount;