import React from 'react';

const Background = (props) => {

    const bgStyle = {
        minHeight: '100%',
        minWidth: '1024px',
        width: '100%',
        height: 'auto',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: -1
    };

    return (
        <img src={props.url} alt="geniall-trama" style={bgStyle} />
    );
}

export default Background;


