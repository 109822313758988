import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Image, Jumbotron } from "react-bootstrap";
import { find, last, map } from 'lodash';
import { GeniallNavbar } from '../';
import '../../css/colors.scss';
import '../../css/PublicProfile.scss';
import api from '../../api';
import ReactCountryFlag from "react-country-flag"
import { getCountries, openInNewTab } from '../../util';
import { Chip } from '@material-ui/core';
import { Facebook, Twitter, LinkedIn } from '@material-ui/icons';

const PublicProfile = props => {
    const userId = props.match.params.userID;
    const [expert, setExpert] = useState({});
    const userData = expert?.userData;
    const name = userData?.name;
    const profilePhoto = expert?.profilePhoto;
    const country = expert?.userData?.country;

    useEffect(() => {
        loadExpert();
    }, []);

    const loadExpert = async () => {
        await api.getExpert(userId)
            .then(res => {
                if (res.data.success) {
                    const expert = res.data.expert;
                    setExpert(expert);
                    const fullName = expert?.userData?.name;
                    document.title = `${fullName.firstName} ${fullName.lastName}`;
                }
            })
            .catch(error => {
                setExpert(false);
                console.log(error);
                if (error.response === undefined) {
                    alert("Error al conectarse al servidor");
                }
            })
    }

    const displayExpertiseChips = expertiseAreas => {
        return (
            map(expertiseAreas, (area, index) => {
                return (
                    <Chip
                        size="medium"
                        variant="outlined"
                        style={{ borderRadius: "10px", fontSize: "1rem" }}
                        key={`${index}${area}`}
                        className="btn-outline-primary-color"
                        label={area}
                    />
                )
            })

        )
    }

    const displayLanguagues = languages => {
        return (
            map(languages, (language, index) => {
                return index + 1 < languages.length ?
                    <span key={`${index}${language}`}>{language.name}, </span> :
                    <span key={`${index}${language}`}>{language.name}</span>
            })
        )
    }

    const displayClients = clients => {
        const style = { fontWeight: "bold", paddingLeft: "1rem", paddingRight: "1rem" };
        return (
            map(clients, (client, index) => {
                return <h5 style={style} key={`${index}${client}`}>{client.name}</h5>
            })
        )
    }

    const countryCode = countryName => {
        const countries = getCountries();
        const code = find(countries, data => data.name_es === countryName).code;
        return code;
    }

    const CertificationCol = props => {
        const [show, toggleShow] = useState(false);
        const certification = props.certification;

        return (
            <Row noGutters className="pp-cert-card">
                <Col xs={12} sm="auto" className="pp-cert-card-icon">
                    <img src={require("../../media/icono-target.svg")} />
                </Col>
                <Col xs={12} sm={9} md={10} className="align-self-center mt-2">
                    <h6 style={{ fontWeight: "bold" }}>Marketing Digital Program Certification</h6>
                    <h6 className="mb-3">Lorem Ipsum is simply dummied text of the printing and typesetting industry.</h6>
                    {show &&
                        <div>
                            <h6>
                                It is a long established fact that a reader will be distracted by the readable content of a page
                                when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                                distribution of letters, as opposed to using ‘Content here, content here’, making it look like
                                readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as
                                their default model text
                            </h6>

                            <Row noGutters className="pp-cert-final-icons">
                                <Col sm="auto">
                                    <img className="pp-cert-img" src={require("../../media/icono-pesos-01.svg")} />
                                    <span>usd 150</span>
                                </Col>
                                <Col sm="auto">
                                    <img className="pp-cert-img" src={require("../../media/icono-creditos-01.svg")} />
                                    <span>50 créditos</span>
                                </Col>
                                <Col sm="auto">
                                    <img className="pp-cert-img" src={require("../../media/icono-certificaciones-01.svg")} />
                                    <span>cursos relacionados</span>
                                </Col>
                                <Col xs={12} lg={true} className="pp-cert-btn">
                                    <Button variant="cert" size="sm">VER INFO</Button>
                                </Col>
                            </Row>
                        </div>
                    }
                </Col>
                <Col xs={12} sm="auto" className="caret">
                    <div onClick={() => toggleShow(!show)} className={show ? "triangle-up" : "triangle-down"} />
                </Col>
            </Row>
        )
    }

    const displayCertifications = certifications => {
        return (
            map(certifications, (certification, index) => {
                return (
                    <CertificationCol key={`${index}${certification}`} certification={certification} />
                )
            })
        )
    }

    return (

        <Container fluid className="pp-root px-0">
            <GeniallNavbar />
            {Object.keys(expert).length > 0 &&
                <div>
                    <Row noGutters className="expert-brief">
                        <Col xs={12} md={4} className="expert-brief-img-col">
                            <Image
                                src={profilePhoto}
                                fluid
                            />
                        </Col>
                        <Col className="expert-brief-txt-col">
                            <h2 style={{ fontWeight: "bold" }}>{name.firstName} {name.lastName}</h2>
                            {expert.expertiseAreas.length > 0 &&
                                <h5>
                                    <span>{expert.expertiseAreas[0]}</span>
                                </h5>
                            }
                            {country &&
                                <ReactCountryFlag
                                    className="mb-4"
                                    countryCode={countryCode(country)}
                                    svg
                                    style={{
                                        width: '2rem',
                                        height: 'auto',
                                    }}
                                />
                            }
                            <Row noGutters className="txt">
                                <Col xs={1} sm={2} xl={1}>
                                    <Image
                                        src={require("../../media/apostrofe-izq.png")}
                                        className="apostrophe"
                                    />
                                </Col>
                                <Col className="expert-brief-quote">
                                    <h5>
                                        {expert.trajectoryBrief}
                                    </h5>
                                </Col>
                                <Col xs={1} sm={2} xl={1}>
                                    <Image
                                        src={require("../../media/apostrofe-der.png")}
                                        className="apostrophe"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 btn-brief-row">
                                <Col xs={12} sm={6} lg={4} xl={3} className="mb-1">
                                    <Button variant="brief">LLAMAR</Button>
                                </Col>
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Button variant="brief">AGENDAR</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row noGutters className="pp-expertise-areas" >
                        <Col xs={12} md={4} id="title-areas">
                            <h5 className="mb-0">Certificaciones y especialista en</h5>
                        </Col>
                        <Col id="chips">
                            {
                                displayExpertiseChips(expert.expertiseAreas)
                            }
                        </Col>
                    </Row>
                    <Row className="pp-video" noGutters>
                        <iframe
                            src="https://www.youtube.com/embed/t3217H8JppI?mute=1"
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Row>
                    <Row className="expert-info-section">
                        <Col xs={12} md="auto" className="fst-col text-primary-color">
                            <Row noGutters>
                                <img src={require("../../media/icono-pesos-01.svg")} />
                                <span>5 usd/min</span>
                            </Row>
                            <Row noGutters>
                                <img src={require("../../media/icono-llamar.png")} />
                                <span>Llamar</span>
                            </Row>
                            <Row noGutters className="mb-0">
                                <img src={require("../../media/icono-agendar-01.svg")} />
                                <span>Agendar</span>
                            </Row>
                        </Col>
                        <Col xs="auto" md={4} lg={3} xl={2} className="snd-col col-2n3">
                            <Row noGutters>
                                <p>
                                    <img src={require("../../media/icono-pais-01.svg")} />
                                    <span className="font-weight-bold">País: </span>
                                    <span>{country}</span>
                                </p>
                            </Row>
                            <Row noGutters>
                                <p>
                                    <img src={require("../../media/icono-idiomas-01.svg")} />
                                    <span className="font-weight-bold">Idiomas: </span>
                                    {
                                        displayLanguagues(expert.languages)
                                    }
                                </p>
                            </Row>
                            <Row noGutters>
                                <p>
                                    <img src={require("../../media/icono-certificaciones-01.svg")} />
                                    <span className="font-weight-bold">Certificaciones: </span>
                                    <span>3</span>
                                </p>
                            </Row>
                            <Row noGutters className="mb-0">
                                <p>
                                    <img src={require("../../media/icono-like-01.svg")} />
                                    {
                                        map([1, 2, 3, 4, 5], (element, index) => {
                                            return (
                                                <img
                                                    key={`${index}${element}`}
                                                    style={{ width: "1.5rem", height: "1.5rem", marginLeft: 0, marginRight: 0 }}
                                                    src={require("../../media/icono-estrella.svg")}
                                                />
                                            )
                                        })
                                    }
                                </p>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto" className="col-2n3">
                            <Row noGutters>
                                <p>
                                    <img src={require("../../media/icono-alumnos-01.svg")} />
                                    <span className="font-weight-bold">Alumnos: </span>
                                    <span>23</span>
                                </p>
                            </Row>
                            <Row noGutters>
                                <p>
                                    <img src={require("../../media/icono-conversaciones-01.svg")} />
                                    <span className="font-weight-bold">Conversaciones: </span>
                                    <span>13</span>
                                </p>
                            </Row>
                            <Row noGutters>
                                <p>
                                    <img src={require("../../media/icono-reloj-01.svg")} />
                                    <span className="font-weight-bold">Minutos: </span>
                                    <span>425</span>
                                </p>
                            </Row>
                            <Row noGutters className="mb-0">
                                <p>
                                    <img src={require("../../media/icono-pesos-01.svg")} />
                                    <span className="font-weight-bold">Ganancia: </span>
                                    <span>usd 8500</span>
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <Row noGutters className="text-center pp-about">
                        <Col xs={11} sm={9} xl={6}>
                            <h2 className="pp-title">Acerca de mí</h2>
                            <h5>{expert.trajectoryFull}</h5>
                        </Col>
                    </Row>
                    <Row noGutters className="justify-content-center">
                        <h2 className="pp-title">Portfolio</h2>
                    </Row>
                    <Row noGutters className="justify-content-center py-4">
                        {
                            displayClients(expert.clients)
                        }
                    </Row>
                    <Row noGutters className="pp-certs">
                        <Col xs={11} md={10} lg={9} xl={8}>
                            <Row noGutters className="justify-content-center">
                                <Col xs="auto">
                                    <h2 className="mb-5 pp-title">Certificaciones</h2>
                                </Col>
                            </Row>
                            {
                                displayCertifications(expert.certifications)
                            }
                        </Col>
                    </Row>
                    <Row noGutters className="pp-quote">
                        <Col xs="auto">
                            <Image
                                src={expert.profilePhoto}
                                fluid
                                style={{ width: "11rem", height: "11rem", border: "1px solid #f1f1f1" }}
                                roundedCircle />
                        </Col>
                        <Col xs={12} md={8} lg={7} xl={6}>
                            <Row noGutters>
                                <Image
                                    src={require("../../media/apostrofe-izq.png")}
                                    className="apostrophe"
                                    style={{ filter: "brightness(0.5)" }}
                                />
                                <Col className="ml-2">
                                    <h5 style={{ lineHeight: "1.5rem" }}>
                                        It is a long established fact that a reader will be distracted by
                                        the readable content of a page when looking at its layout. The point
                                        of using Lorem Ipsum is that it has a more-or-less normal distribution
                                        of letters, as opposed to using ‘Content here,
                                    </h5>
                                    <h5 className="font-weight-bold">Inés Pérez - Dreamworks</h5>
                                </Col>
                                <Image
                                    src={require("../../media/apostrofe-der.png")}
                                    className="apostrophe"
                                    style={{ filter: "brightness(0.5)", marginTop: "-0.5rem" }}
                                />
                            </Row>

                        </Col>
                    </Row>
                    <Row noGutters className="footer">
                        <Col xs={12} sm="auto" className="text-center">
                            Copyright {'\u00A9'} 2021 Geniall
                            <Row className="justify-content-center" style={{ columnGap: "1rem", marginTop: "1rem" }}>
                                <Facebook style={{ cursor: "pointer" }} fontSize="large" onClick={() => openInNewTab("https://www.facebook.com/geniallinfo/")} />
                                <Twitter style={{ cursor: "pointer" }} fontSize="large" onClick={() => openInNewTab("https://twitter.com/GeniAll_latam")} />
                                <LinkedIn style={{ cursor: "pointer" }} fontSize="large" onClick={() => openInNewTab("https://www.linkedin.com/company/geniall/")} />                                
                            </Row>
                        </Col>
                        <Col xs={12} sm="auto">
                            <Row noGutters className="footer-right">
                                <h6>
                                    <a className="footer-a" href="mailto:info@geniall.io">
                                        Contacto
                                        </a>
                                </h6>
                                <h6>
                                    <a className="footer-a" target="_blank" href="https://geniall.io/privacy-policy/">
                                        Privacidad
                                    </a>
                                </h6>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            {
                !expert &&
                <Row className="not-found" noGutters>
                    <Col xs={11} sm={5}>
                        <h1>Experto no encontrado</h1>
                        <span><h5 className="mt-5">Este perfil no está disponible.</h5></span>
                    </Col>
                </Row>
            }
        </Container>
    )
}


export default PublicProfile;