import React, { useState, useEffect } from 'react';
import '../../../css/colors.scss';
import '../../../css/ExpertProfile.scss';
import { ConfirmationDialog } from '../..';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import { cloneDeep, map, remove, forEach } from 'lodash';
import { Edit } from '@material-ui/icons';
import updateExpert from '../../../api/expert';

const AchievementsModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const achievements = expert?.achievements;
    const isEditView = props.index !== undefined;
    const index = isEditView ? props.index : -1;

    const [title, setTitle] = useState(isEditView ? achievements[index].title : "");
    const [description, setDescription] = useState(isEditView ? achievements[index].description : "");
    const [date, setDate] = useState(isEditView ? achievements[index].date.split("T")[0] : "");
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            if (isEditView) updateAchievements();
            else addAchievement();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => formData.append(key, JSON.stringify(value)))
        return formData;
    }

    const deleteAchievement = async _ => {
        remove(expert.achievements, (_, i) => { return i === index; });
        await update();
    }

    const updateAchievements = async _ => {
        const achievements = {
            title: title,
            description: description,
            date: date
        }
        expert.achievements[index] = achievements;
        await update();
    }

    const addAchievement = async _ => {
        const achievements = {
            title: title,
            description: description,
            date: date
        }
        expert.achievements.push(achievements);
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formTitle">
                                <Form.Label>Título</Form.Label>
                                <Form.Control
                                    value={title}
                                    onChange={event => setTitle(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: 1er Lugar Concurso..."
                                    name="title" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona un título
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control
                                    value={description}
                                    onChange={event => setDescription(event.target.value)}
                                    as="textarea"
                                    rows="3"
                                    required
                                    name="description" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una descripción
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDate">
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control
                                    value={date}
                                    onChange={event => setDate(event.target.value)}
                                    type="date"
                                    required
                                    name="date" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona la fecha
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={!isEditView ? "justify-content-end" : "justify-content-between"}>
                        {isEditView &&
                            <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                                Borrar Premio
                            </Button>
                        }
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar Premio"
                    message="¿Realmente deseas borrar este premio de tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteAchievement} />
            }
        </div>
    );
}

const AddAchievement = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <AchievementsModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Añadir Premio"} />
            </Row>
        </div>
    );
}

const EditAchievement = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <AchievementsModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Premio"}
                    index={props.index} />
            </Row>
        </div>
    );
}

const DisplayAchievements = props => {

    const expert = props.expert;
    const [achievementIndex, setAchievementIndex] = useState(-1);

    useEffect(() => {
        props.toggle(true);
    }, [achievementIndex]);

    const expertRows = map(expert?.achievements, (achievement, index) => {
        const year = new Date(achievement.date).getFullYear();
        const mb = index === expert.achievements.length - 1 ? "" : "mb-3"
        const borderBottom = index === expert.achievements.length - 1 ? "" : "border-bottom";
        return (
            <Row key={index} className={`${mb} ${borderBottom} mx-1`}>
                <Col xs={9}>
                    <p>
                        <span className="font-weight-bold card-headers">{achievement.title}</span><br />
                        <span>{achievement.description}</span><br />
                        <span className="text-gray"><time>{year}</time></span>
                    </p>
                </Col>
                <Col className="text-right" xs={3}>
                    <Edit
                        titleAccess={"Editar Premio"}
                        className="primary-color pointer-cursor"
                        onClick={_ => setAchievementIndex(index)}
                    />
                </Col>
            </Row>
        );
    });

    return (
        <div>
            {props.show && achievementIndex !== -1 &&
                <EditAchievement
                    toggle={props.toggle}
                    expert={expert}
                    index={achievementIndex} />}
            {expertRows}
        </div>
    );
}

export { AddAchievement, DisplayAchievements };
