import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { getExpertiseAreas } from '../util';
import { orderBy, toLower, startCase, last, map, sortBy } from 'lodash';

const AreaSelector = props => {

    const selectValues = props.value;
    const expertiseAreasList = getExpertiseAreas();
    const [selectOptions, setSelectOptions] = useState(
        map(sortBy(expertiseAreasList), area => {
            return { label: area, value: area }
        })
    );

    const customSelectStyles = {
        multiValue: (styles, _) => {
            return {
                ...styles,
                backgroundColor: 'white',
                border: '1.5px solid #714FF1',
                borderRadius: 10
            };
        },
        multiValueLabel: (styles, _) => {
            return {
                ...styles,
                color: '#714FF1',
                fontWeight: 'bold'
            };
        },
        multiValueRemove: (styles, _) => {
            return {
                ...styles,
                color: 'gray',
                ':hover': {
                    backgroundColor: 'transparent',
                    color: 'black',
                }
            };
        },
    }

    const onChangeSelect = (currentValues, actionMeta) => {
        if (actionMeta.action === "create-option") {
            const index = selectOptions.length;
            const label = last(currentValues).label;
            const objValue = { value: index, label: startCase(toLower(label)) }
            setSelectOptions(
                orderBy(
                    [...selectOptions, objValue],
                    ['label'],
                    ['asc']
                )
            )
            currentValues.pop();
            currentValues.push(objValue);
        }
        props.setValues(currentValues);
    }

    return (
        <CreatableSelect
            value={selectValues}
            isMulti
            styles={customSelectStyles}
            options={selectOptions}
            onChange={onChangeSelect}
            placeholder="Seleccionar áreas..."
            formatCreateLabel={(value) => `Agregar "${value}"`}
        />
    )
}

export default AreaSelector;
