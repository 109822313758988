import React, { useState, useEffect } from "react";
import { ExpertProfile, ExpertAccount } from ".";
import "../../css/colors.scss";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  DropdownButton,
  InputGroup,
  Dropdown,
  FormControl,
  Card,
  ListGroupItem,
  ListGroup,
  Navbar,
  NavDropdown,
  Nav,
  CardDeck,
  Modal,
} from "react-bootstrap";
import { ProSidebar, Menu, SubMenu, MenuItem } from "react-pro-sidebar";
import { lowerCase, map } from "lodash";
import {
  AttachMoney,
  MonetizationOn,
  Person,
  Timelapse,
} from "@material-ui/icons";
//import { openInNewTab } from '../../util';
import api from "../../api";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
const VIEW = "view";
const CREATE = "create";

// NOTE Componente Card para mostrar los cursos

const CardCourse = (props) => {
  let [editar, setEditar] = useState(false);

  // const showEdit = () => {
  //   setEditar((prevState) => !prevState);
  // };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Card
      onMouseEnter={() => setEditar(true)}
      onMouseLeave={() => setEditar(false)}
      style={{ width: "18rem" }}
    >
      <Card.Img variant="top" src={props.course.image} />
      <Card.ImgOverlay>
        {editar == true && (
          <Card.Header>
            <EditIcon onClick={handleShow} style={{ cursor: "pointer" }} />
          </Card.Header>
        )}

        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Editar curso <span className="muted">{props.course.name}</span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col md="auto" className="justify-content-center mb-3">
                  <Card style={{ width: "18rem" }}>
                    <Card.Img variant="top" src={props.course.image} />
                    <Card.Body>
                      <Card.Title>{props.course.name}</Card.Title>
                      <Card.Text>{props.course.description}</Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem>
                        <Container>
                          <Row>
                            <Col>
                              <AttachMoney fontSize="small" />
                              <span className="ml-2 text-muted">
                                {props.course.price}
                              </span>
                            </Col>
                            <Col>
                              <MonetizationOn fontSize="small" />
                              <span className="ml-2 text-muted">
                                {props.course.creditsPrice}
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </ListGroupItem>
                      <ListGroupItem>
                        <Container>
                          <Row>
                            <Col>
                              <Person fontSize="small" />
                              <span className="ml-2 text-muted">
                                {props.course.capacity}
                              </span>
                            </Col>
                            <Col>
                              <Timelapse fontSize="small" />
                              <span
                                style={{ fontSize: "small" }}
                                className="ml-1 text-muted"
                              >
                                {props.course.quantity}
                              </span>
                              <span
                                style={{ fontSize: "small" }}
                                className="ml-1 text-muted"
                              >
                                {props.course.duration}
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </ListGroupItem>
                    </ListGroup>
                    <Card.Footer className="text-muted">
                      <Container>
                        <Row>
                          <Col>
                            <span
                              style={{ fontSize: "small" }}
                              className="font-weight-bold"
                            >
                              Inicia:
                            </span>
                          </Col>
                          <Col>
                            <span style={{ fontSize: "small" }}>
                              {props.course.startDate}
                            </span>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md="auto" className="px-5">
                  <Form>
                    <Form.Group>
                      <Form.Group>
                        <Form.Label>Fecha de inicio</Form.Label>
                        {/* <p>{startDate}</p> */}
                        <Form.Control
                          value={props.course.startDate}
                          // onChange={(event) => setStartDate(event.target.value)}
                          type="date"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Duración</Form.Label>
                        <InputGroup>
                          <FormControl
                            defaultValue={props.course.quantity}
                            // onChange={(event) => setQuantity(event.target.value)}
                            type="number"
                            aria-describedby="basic-addon2"
                          />

                          <DropdownButton
                            as={InputGroup.Append}
                            variant="outline-secondary"
                            title={props.course.duration}
                            id="input-group-dropdown-2"
                          >
                            <Dropdown.Item
                            // onClick={() => setDuration("Días")}
                            >
                              Días
                            </Dropdown.Item>
                            <Dropdown.Item
                            // onClick={() => setDuration("Semanas")}
                            >
                              Semanas
                            </Dropdown.Item>
                            <Dropdown.Item
                            //  onClick={() => setDuration("Meses")}
                            >
                              Meses
                            </Dropdown.Item>
                          </DropdownButton>
                        </InputGroup>
                      </Form.Group>
                      <Form.Label>El precio de tu curso.</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <AttachMoney fontSize="small" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          value={props.course.price}
                          // onChange={(event) => setPrice(event.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Costo en Créditos</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <MonetizationOn fontSize="small" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          value={props.course.creditsPrice}
                          // onChange={(event) => setCreditsPrice(event.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Capacidad del curso</Form.Label>
                      <Form.Control
                        type="number"
                        value={props.course.capacity}
                        // onChange={(event) => setCapacity(event.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="auto">
                  <Form.Group>
                    <Form.Label>El nombre de tu curso:</Form.Label>
                    <Form.Control
                      type="text"
                      value={props.course.name}
                      placeholder="Nombre del curso"
                      // onChange={(event) => setName(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Describe tu curso, por favor.</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Descripcion"
                      value={props.course.description}
                      rows={3}
                      // onChange={(event) => setDescription(event.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      <span>
                        Coloca una imagen de portada para tu nuevo curso.
                      </span>
                    </Form.Label>
                    <Form.File
                      // onChange={(e) => newImage(e)}
                      id="custom-file"
                      label="Portada del curso"
                      custom
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="success" onClick={handleClose}>
              Guardar cambios
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.ImgOverlay>
      <Card.Body>
        <Card.Title>{props.course.title}</Card.Title>
        <Card.Text>{props.course.description}</Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
          <Container>
            <Row>
              <Col>
                <AttachMoney fontSize="small" />
                <span className="ml-2 text-muted">{props.course.price}</span>
              </Col>
              <Col>
                <MonetizationOn fontSize="small" />
                <span className="ml-2 text-muted">
                  {props.course.creditsPrice}
                </span>
              </Col>
            </Row>
          </Container>
        </ListGroupItem>
        <ListGroupItem>
          <Container>
            <Row>
              <Col>
                <Person fontSize="small" />
                <span className="ml-2 text-muted">{props.course.capacity}</span>
              </Col>
              <Col>
                <Timelapse fontSize="small" />
                <span style={{ fontSize: "small" }} className="ml-1 text-muted">
                  {props.course.quantity}
                </span>
                <span style={{ fontSize: "small" }} className="ml-1 text-muted">
                  {props.course.duration}
                </span>
              </Col>
            </Row>
          </Container>
        </ListGroupItem>
      </ListGroup>
      <Card.Footer className="text-muted">
        <Container>
          <Row>
            <Col>
              <span style={{ fontSize: "small" }} className="font-weight-bold">
                Inicia:
              </span>
            </Col>
            <Col>
              <span style={{ fontSize: "small" }}>
                {props.course.startDate}
              </span>
            </Col>
          </Row>
        </Container>
      </Card.Footer>
    </Card>
  );
};

const ViewCertifications = (props) => {
  const expert = props.expert;
  const [courses, setCourses] = useState(() => {
    let temp = {
      name: "titulo",
      image: "https://source.unsplash.com/random/286x180",
      description: "titulo",
      price: 1,
      creditsPrice: 2,
      capacity: 3,
      quantity: 1,
      duration: "meses",
      startDate: "2021",
      id: null,
    };
    return [0, 1].map((element) => {
      temp.id = element;
      return temp;
    });
  });

  // useEffect((_) => {
  //   loadCourses();
  // }, []);

  // const loadCourses = async () => {
  //   await api
  //     .getCoursesByExpert(expert?._id)
  //     .then((res) => {
  //       if (res.data.success) {
  //         const courses = res.data.courses;
  //         setCourses(courses);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response !== undefined) {
  //         alert(error.response.data.error);
  //       } else {
  //         alert("Error al conectarse al servidor");
  //       }
  //     });
  // };

  return (
    <Container>
      <Row className="my-md-5">
        <Col className="justify-content-center">
          <Navbar bg="light" expand="lg">
            {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Form inline>
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="mr-sm-2"
                />
                <SearchIcon />
              </Form>
              <Nav className="mr-auto">
                <NavDropdown title="Filtrar por" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Tema</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Mayor calificación
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Precio</NavDropdown.Item>
                  {/* <NavDropdown.Divider /> */}
                  {/* <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item> */}
                </NavDropdown>
                <NavDropdown title="Ordenar" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">
                    Más reciente
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Más relevante
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Otro</NavDropdown.Item>
                  {/* <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item> */}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
      <Row className="justify-content-around border rounded p-3 m-5 ">
        {map(courses, (course) => {
          return (
            <Col md="auto">
              <CardDeck>
                <CardCourse course={course} key={course.id} />
              </CardDeck>
            </Col>
          );
        })}
      </Row>
    </Container>
    // <div>
    //   {map(courses, (course) => {
    //     return <p key={course._id}>{course.name}</p>;
    //   })}
    // </div>
  );
};

const CreateCertification = () => {
  const [image, setImage] = useState(
    "https://source.unsplash.com/random/286x180"
  );
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0.0);
  const [creditsPrice, setCreditsPrice] = useState(0.0);
  const [capacity, setCapacity] = useState(1);
  // const [startDate, setStartDate] = useState("2021-02-11");
  const [startDate, setStartDate] = useState(() => {
    let date = new Date();
    return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
  });
  const [duration, setDuration] = useState("Plazo");
  const [quantity, setQuantity] = useState(0);

  const newImage = (event) => {
    let file = event.target.files[0];
    let fileR = new FileReader();
    fileR.onload = (e) => {
      setImage(e.target.result);
    };

    fileR.readAsDataURL(file);
  };

  return (
    <div>
      <Container>
        <Row noGutters className="justify-content-center mb-5">
          <h3>Registrar Curso</h3>
        </Row>
        <Row
          noGutters
          className="justify-content-around mt-md-5 pt-md-5 border rounded"
          // style={{ border: "1px  #141414" }}
        >
          {/* <Col md="12" className="justify-content-center mb-2 bg-warning">
          </Col> */}
          <Col md="auto" className="justify-content-center mb-3">
            <p className="h3">Tu nuevo curso:</p>

            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={image} />
              <Card.Body>
                <Card.Title>
                  <Form.Group>
                    {/* <Form.Label>
                      <span
                        style="font-size: xx-small"
                        className="font-weight-light"
                      >
                        Nombre del curso:
                      </span>
                    </Form.Label> */}
                    <Form.Control
                      type="text"
                      value={name}
                      placeholder="Nombre del curso"
                      onChange={(event) => setName(event.target.value)}
                    />
                  </Form.Group>
                </Card.Title>
                <Card.Text>
                  <Form.Group>
                    {/* <Form.Label>Describe tu curso, por favor.</Form.Label> */}
                    <Form.Control
                      as="textarea"
                      placeholder="Descripcion"
                      value={description}
                      rows={3}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </Form.Group>
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <Container>
                    <Row>
                      <Col>
                        <AttachMoney fontSize="small" />
                        <span className="ml-2 text-muted">{price}</span>
                      </Col>
                      <Col>
                        <MonetizationOn fontSize="small" />
                        <span className="ml-2 text-muted">{creditsPrice}</span>
                      </Col>
                    </Row>
                  </Container>
                </ListGroupItem>
                <ListGroupItem>
                  <Container>
                    <Row>
                      <Col>
                        <Person fontSize="small" />
                        <span className="ml-2 text-muted">{capacity}</span>
                      </Col>
                      <Col>
                        <Timelapse fontSize="small" />
                        <span
                          style={{ fontSize: "small" }}
                          className="ml-1 text-muted"
                        >
                          {quantity}
                        </span>
                        <span
                          style={{ fontSize: "small" }}
                          className="ml-1 text-muted"
                        >
                          {duration}
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </ListGroupItem>
              </ListGroup>
              <Card.Footer className="text-muted">
                <Container>
                  <Row>
                    <Col>
                      <span
                        style={{ fontSize: "small" }}
                        className="font-weight-bold"
                      >
                        Inicia:
                      </span>
                    </Col>
                    <Col>
                      <span style={{ fontSize: "small" }}>{startDate}</span>
                    </Col>
                  </Row>
                </Container>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="5" className="px-5">
            <Form>
              <Form.Group>
                <Form.Label>El precio de tu curso.</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <AttachMoney fontSize="small" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    value={price}
                    onChange={(event) => setPrice(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label>Costo en Créditos</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <MonetizationOn fontSize="small" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    value={creditsPrice}
                    onChange={(event) => setCreditsPrice(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label>Capacidad del curso</Form.Label>
                <Form.Control
                  type="number"
                  value={capacity}
                  onChange={(event) => setCapacity(event.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Fecha de inicio</Form.Label>
                {/* <p>{startDate}</p> */}
                <Form.Control
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                  type="date"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Duración</Form.Label>
                <InputGroup>
                  <FormControl
                    defaultValue={quantity}
                    onChange={(event) => setQuantity(event.target.value)}
                    type="number"
                    aria-describedby="basic-addon2"
                  />

                  <DropdownButton
                    as={InputGroup.Append}
                    variant="outline-secondary"
                    title={duration}
                    id="input-group-dropdown-2"
                  >
                    <Dropdown.Item onClick={() => setDuration("Días")}>
                      Días
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setDuration("Semanas")}>
                      Semanas
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setDuration("Meses")}>
                      Meses
                    </Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  <span>Coloca una imagen de portada para tu nuevo curso.</span>
                </Form.Label>
                <Form.File
                  onChange={(e) => newImage(e)}
                  id="custom-file"
                  label="Portada del curso"
                  custom
                />
              </Form.Group>

              <Button
                className="my-md-5 mx-auto"
                variant="primary"
                type="submit"
              >
                Crear curso
              </Button>
            </Form>
          </Col>
        </Row>

        <Row noGutters>
          <Container fluid>
            <Row></Row>
          </Container>
        </Row>
      </Container>
    </div>
  );
};

const Certifications = (props) => {
  const comp =
    props.component === undefined ? VIEW : lowerCase(props.component);

  return (
    <Container fluid className="px-0">
      {comp === VIEW && <ViewCertifications expert={props.expert} />}
      {comp === CREATE && <CreateCertification />}
    </Container>
  );
};

export default Certifications;
