const env = "prod";

const local = {
  server: {
    protocol: "http",
    host: "localhost",
    port: "3000",
  },
};

const rafa = {
  server: {
    protocol: "http",
    host: "192.168.100.23",
    //host: '192.168.15.31',
    port: "3000",
  },
};

const prod = {
  server: {
    protocol: "http",
    host: "geniall-lb-2-1634163799.us-east-1.elb.amazonaws.com",
    port: "80",
  },
};

const config = {
  local,
  rafa,
  prod,
};

export default config[env];
