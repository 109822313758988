import React from "react";
import { ExpertDashboard, PublicProfile } from "./components/expert";
import { StudentDashboard } from "./components/student";
import { SelectDashboard } from "./components";
import LandingPage from "./landing/LandingPage";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter forceRefresh>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login" component={LandingPage}>
            {localStorage.getItem("id") && <Redirect to="/dashboard" />}
          </Route>
          <Route exact path="/registro" component={LandingPage} />
          <Route
            exact
            path="/registro/registro-experto"
            component={LandingPage}
          />
          <Route exact path="/dashboard" component={SelectDashboard}>
            {!localStorage.getItem("id") && <Redirect to="/" />}
          </Route>
          <Route exact path="/dashboard/experto" component={ExpertDashboard}>
            {!localStorage.getItem("id") && <Redirect to="/" />}
          </Route>
          <Route exact path="/dashboard/estudiante" component={StudentDashboard}>
            {!localStorage.getItem("id") && <Redirect to="/" />}
          </Route>
          <Route exact path="/dashboard/empresa" component={ExpertDashboard}>
            {!localStorage.getItem("id") && <Redirect to="/" />}
          </Route>
          <Route path="/experto/:userID" component={PublicProfile} />
          <Route component={LandingPage} />
          {/* <Route exact path="/registro/registro-usuario" component={UserRegister} />
          <Route exact path="/usuario" component={UserDashboard} /> */}
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
