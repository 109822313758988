import React, { useState } from 'react';
import '../../../css/colors.scss';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import ConfirmationDialog from '../../ConfirmationDialog';
import { cloneDeep, forEach } from 'lodash';
import updateExpert from '../../../api/expert';

const TrajectoryModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const [trajectory, setTrajectory] = useState(expert?.trajectoryFull);
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            updateTrajectory();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => formData.append(key, JSON.stringify(value)))
        return formData;
    }

    const deleteTrajectory = async _ => {
        expert.trajectoryFull = "";
        await update();
    }

    const updateTrajectory = async () => {
        expert.trajectoryFull = trajectory.trim();
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formTitle">
                                <Form.Control
                                    value={trajectory}
                                    onChange={event => setTrajectory(event.target.value)}
                                    as="textarea"
                                    rows="20"
                                    name="trajectory" />
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={"justify-content-between"}>
                        <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                            Borrar BIO Personal
                        </Button>
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar bio personal"
                    message="¿Realmente deseas borrar tu bio personal completa tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteTrajectory} />
            }
        </div>
    );
}

const EditTrajectory = props => {

    return (
        <div>
            <Row className="justify-content-center">
                <TrajectoryModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar BIO personal"} />
            </Row>
        </div>
    );

}

export { EditTrajectory };
