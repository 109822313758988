import React, { useState, useEffect } from 'react';
import '../../../css/colors.scss';
import '../../../css/ExpertProfile.scss';
import ConfirmationDialog from '../../ConfirmationDialog';
import { Button, Col, Row, Form, Modal, Image } from 'react-bootstrap';
import { cloneDeep, map, remove, forEach } from 'lodash';
import { Edit } from '@material-ui/icons';
import updateExpert from '../../../api/expert';

const WorkExperienceModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const workExperience = expert?.workExperience;
    const isEditView = props.index !== undefined;
    const index = isEditView ? props.index : -1;

    const [position, setPosition] = useState(isEditView ? workExperience[index].position : "");
    const [company, setCompany] = useState(isEditView ? workExperience[index].company : "");
    const [workType, setWorkType] = useState(isEditView ? workExperience[index].workType : "");
    const [currentWork, setCurrentWork] = useState(isEditView ? workExperience[index].currentWork : false);
    const [startDate, setStartDate] = useState(isEditView ? workExperience[index].startDate.split("T")[0] : "");
    const [endDate, setEndDate] = useState(isEditView ?
        workExperience[index].expires ? workExperience[index].endDate.split("T")[0] : "" : "");
    const [description, setDescription] = useState(isEditView ? workExperience[index].description : "");
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            if (isEditView) updateWorkExperience();
            else addWorkExperience();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => formData.append(key, JSON.stringify(value)))
        return formData;
    }

    const deleteWorkExperience = async _ => {
        remove(expert.workExperience, (_, i) => { return i === index; });
        await update();
    }

    const updateWorkExperience = async _ => {
        const work = {
            ...expert.workExperience[index],
            company: company,
            position: position,
            workType: workType,
            currentWork: currentWork,
            startDate: startDate,
            endDate: endDate,
            description: description
        }
        expert.workExperience[index] = work;
        await update();
    }

    const addWorkExperience = async _ => {
        const work = {
            company: company,
            position: position,
            workType: workType,
            currentWork: currentWork,
            startDate: startDate,
            endDate: endDate,
            description: description
        }
        expert.workExperience.push(work);
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
                className="pl-0"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formCompany">
                                <Form.Label>Empresa o Institución</Form.Label>
                                <Form.Control
                                    value={company}
                                    onChange={event => setCompany(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: Google"
                                    name="company" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una empresa o institución
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formPosition">
                                <Form.Label>Posición</Form.Label>
                                <Form.Control
                                    value={position}
                                    onChange={event => setPosition(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: Gerente del área de..."
                                    name="position" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una posición
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formWorkType">
                                <Form.Label>Tipo de empleo</Form.Label>
                                <Form.Control
                                    value={workType}
                                    onChange={event => setWorkType(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: Jornada completa, autónomo..."
                                    name="workType" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona el tipo de empleo
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formStartDate">
                                <Form.Label>Fecha de Inicio</Form.Label>
                                <Form.Control
                                    value={startDate}
                                    onChange={event => setStartDate(event.target.value)}
                                    type="date"
                                    required
                                    name="startDate" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona la fecha de inicio
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formEndDate">
                                <Form.Label>Fecha de Término</Form.Label>
                                <Form.Control
                                    value={endDate}
                                    onChange={event => setEndDate(event.target.value)}
                                    type="date"
                                    required
                                    disabled={currentWork}
                                    name="endDate" />
                                <Form.Check
                                    type="checkbox"
                                    checked={currentWork}
                                    onChange={_ => setCurrentWork(!currentWork)}
                                    label="trabajo actual" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona la fecha de término
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label>Descripción del trabajo</Form.Label>
                                <Form.Control
                                    value={description}
                                    onChange={event => setDescription(event.target.value)}
                                    as="textarea"
                                    rows="3"
                                    placeholder="Ej: Encargado de realizar las actividades..."
                                    name="description" />
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={!isEditView ? "justify-content-end" : "justify-content-between"}>
                        {isEditView &&
                            <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                                Borrar Trabajo
                            </Button>
                        }
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar Trabajo"
                    message="¿Realmente deseas borrar este trabajo de tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteWorkExperience} />
            }
        </div>
    );
}

const AddWorkExperience = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <WorkExperienceModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Añadir Trabajo"} />
            </Row>
        </div>
    );
}

const EditWorkExperience = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <WorkExperienceModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Trabajo"}
                    index={props.index} />
            </Row>
        </div>
    );
}

const DisplayWorkExperience = props => {

    const expert = props.expert;
    const [workExperienceIndex, setWorkExperienceIndex] = useState(-1);

    useEffect(() => {
        props.toggle(true);
    }, [workExperienceIndex]);

    const expertRows = map(expert?.workExperience, (work, index) => {
        const startYear = new Date(work.startDate).getFullYear();
        const endYear = new Date(work.endDate).getFullYear();
        const mb = index === expert.workExperience.length - 1 ? "" : "mb-3"
        const borderBottom = index === expert.workExperience.length - 1 ? "" : "border-bottom";
        return (
            <Row key={index} className={`${mb} ${borderBottom} mx-1`}>
                <Col xs={true} className="align-self-center">
                    <p>
                        <span className="font-weight-bold card-headers">{work.position}</span><br />
                        <span>{work.company} - {work.workType}</span><br />
                        {work.currentWork ?
                            (<span className="text-gray">
                                <time>{startYear}</time>
                            </span>) :
                            (<span className="text-gray">
                                <time>{startYear}</time> – <time>{endYear}</time>
                            </span>)
                        }
                    </p>
                    <p className="mb-0">
                        {work.description &&
                            <span>{work.description}</span>
                        }
                    </p>
                </Col>
                <Col className="text-right" xs={2}>
                    <Edit
                        titleAccess={"Editar Trabajo"}
                        className="primary-color pointer-cursor"
                        onClick={_ => setWorkExperienceIndex(index)}
                    />
                </Col>
            </Row >
        );
    });

    return (
        <div>
            {props.show && workExperienceIndex !== -1 &&
                <EditWorkExperience
                    toggle={props.toggle}
                    expert={expert}
                    index={workExperienceIndex} />}
            {expertRows}
        </div>
    );
}

export { AddWorkExperience, EditWorkExperience, DisplayWorkExperience };
