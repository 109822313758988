import React, { useState, useEffect } from 'react';
import '../../../css/colors.scss';
import '../../../css/ExpertProfile.scss';
import ConfirmationDialog from '../../ConfirmationDialog';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import { cloneDeep, map, remove, forEach } from 'lodash';
import { Edit } from '@material-ui/icons';
import updateExpert from '../../../api/expert';
import { SocialIcon } from 'react-social-icons';

const SocialMediaModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const socialMedia = expert?.socialMedia;
    const isEditView = props.index !== undefined;
    const index = isEditView ? props.index : -1;

    const [url, setURL] = useState(isEditView ? socialMedia[index].url : "");
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            if (isEditView) updateSocialMedia();
            else addSocialMedia();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => formData.append(key, JSON.stringify(value)))
        return formData;
    }

    const deleteSocialMedia = async _ => {
        remove(expert.socialMedia, (_, i) => { return i === index; });
        await update();
    }

    const updateSocialMedia = async _ => {
        const socialMedia = {
            url: url
        }
        expert.socialMedia[index] = socialMedia;
        await update();
    }

    const addSocialMedia = async _ => {
        const socialMedia = {
            url: url
        }
        expert.socialMedia.push(socialMedia);
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formTitle">
                                <Form.Label>URL de la Red Social</Form.Label>
                                <Form.Control
                                    value={url}
                                    onChange={event => setURL(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: https://www.facebook.com/usuario"
                                    name="title" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una url
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={!isEditView ? "justify-content-end" : "justify-content-between"}>
                        {isEditView &&
                            <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                                Borrar Red Social
                            </Button>
                        }
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar Red Social"
                    message="¿Realmente deseas borrar esta red social de tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteSocialMedia} />
            }
        </div>
    );
}

const AddSocialMedia = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <SocialMediaModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Añadir Red Social"} />
            </Row>
        </div>
    );
}

const EditSocialMedia = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <SocialMediaModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Red Social"}
                    index={props.index} />
            </Row>
        </div>
    );
}

const DisplaySocialMedia = props => {

    const expert = props.expert;
    const [socialMediaIndex, setSocialMediaIndex] = useState(-1);

    const linkStyle = {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        flex: 1
    }

    useEffect(() => {
        props.toggle(true);
    }, [socialMediaIndex]);

    const expertRows = map(expert?.socialMedia, (socialMedia, index) => {
        const mb = index === expert.socialMedia.length - 1 ? "" : "mb-2"
        const borderBottom = index === expert.socialMedia.length - 1 ? "" : "border-bottom";
        const url = socialMedia.url.startsWith("http://") || socialMedia.url.startsWith("https://") ? socialMedia.url : "http://" + socialMedia.url;
        return (
            <Row key={index} className={`${mb} ${borderBottom} mx-1`}>
                <Col xs={10} className="mb-2">
                    <Row className="align-items-center">
                        <SocialIcon url={url} target="_blank" />
                        <a href={url} style={linkStyle} target="_blank" className="ml-2">{socialMedia.url}</a>
                    </Row>
                </Col>
                <Col className="text-right" xs={2}>
                    <Edit
                        titleAccess={"Editar Red Social"}
                        className="primary-color pointer-cursor"
                        onClick={_ => setSocialMediaIndex(index)}
                    />
                </Col>
            </Row>
        );
    });

    return (
        <div>
            {props.show && socialMediaIndex !== -1 &&
                <EditSocialMedia
                    toggle={props.toggle}
                    expert={expert}
                    index={socialMediaIndex} />}
            {expertRows}
        </div>
    );
}

export { AddSocialMedia, EditSocialMedia, DisplaySocialMedia };

