import React from 'react';
import '../css/colors.scss';
import { Button, Modal, Col } from 'react-bootstrap';

const ConfirmationDialog = props => {

    const handleClose = _ => {
        props.close();
        props.onConfirmDelete();        
    }

    return (
        <Modal show={true}
            size={"md"}
            centered
            backdrop="static"
            style={{
                "background-color": "hsla(0, 0%, 31%, 0.8)"
            }}
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer className="justify-content-stretch">
                <Button variant="secondary" className="flex-grow-1" onClick={props.close}>
                    No
                </Button>
                <Button variant="danger" className="flex-grow-1" onClick={handleClose}>
                    Sí
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationDialog;

