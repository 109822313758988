import React, { useState, useEffect } from 'react';
import '../../../css/colors.scss';
import '../../../css/ExpertProfile.scss';
import ConfirmationDialog from '../../ConfirmationDialog';
import { Button, Col, Row, Form, Modal, Image } from 'react-bootstrap';
import { cloneDeep, map, remove, forEach } from 'lodash';
import { Edit } from '@material-ui/icons';
import updateExpert from '../../../api/expert';

const CertificationsModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const certifications = expert?.certifications;
    const isEditView = props.index !== undefined;
    const index = isEditView ? props.index : -1;

    const [name, setName] = useState(isEditView ? certifications[index].name : "");
    const [picture, setPicture] = useState(isEditView ? certifications[index].picture : "");
    const [company, setCompany] = useState(isEditView ? certifications[index].company : "");
    const [expires, setExpires] = useState(isEditView ? certifications[index].expires : false);
    const [expeditionDate, setExpeditionDate] = useState(isEditView ? certifications[index].expeditionDate.split("T")[0] : "");
    const [expirationDate, setExpirationDate] = useState(isEditView ?
        certifications[index].expires ? certifications[index].expirationDate.split("T")[0] : "" : "");
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);
    var fileToUpload = React.createRef();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            if (isEditView) updateCertifications();
            else addCertification();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => {
            if (key !== 'newCertPhoto') {
                formData.append(key, JSON.stringify(value));
            } else {
                formData.append(key, value);
            }
        })
        return formData;
    }

    const deleteCertification = async _ => {
        remove(expert.certifications, (_, i) => { return i === index; });
        await update();
    }

    const updateCertifications = async _ => {
        const certifications = {
            ...expert.certifications[index],
            name: name,
            company: company,
            expires: expires,
            expeditionDate: expeditionDate,
            expirationDate: expirationDate
        }
        expert.certifications[index] = certifications;
        if (fileToUpload.current.files.length === 1) {
            expert.newCertPhoto = fileToUpload.current.files[0];
            expert.certID = index;
        }
        await update();
    }

    const addCertification = async _ => {
        const certifications = {
            name: name,
            company: company,
            expires: expires,
            expeditionDate: expeditionDate,
            expirationDate: expirationDate
        }
        expert.certifications.push(certifications);
        if (fileToUpload.current.files.length === 1) {
            expert.newCertPhoto = fileToUpload.current.files[0];
            expert.certID = expert.certifications.length - 1;
        }
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
                className="pl-0"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        {picture !== "" && picture !== undefined &&
                            <Form.Row>
                                <Image
                                    src={picture}
                                    fluid
                                    style={{ width: "auto", height: "10rem", border: "1px solid #dedede" }}
                                />
                            </Form.Row>
                        }
                        <Form.Row>
                            <Form.Group as={Col} controlId="formPicture">
                                <Form.Label>Seleccionar imagen</Form.Label>
                                <Form.Control
                                    onChange={event => {
                                        if (event.target?.files?.length > 0) {
                                            setPicture(URL.createObjectURL(event.target.files[0]))
                                        }
                                    }}
                                    type="file"
                                    accept="image/*"
                                    ref={fileToUpload}
                                    name="picture"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formName">
                                <Form.Label>Título de la Certificación</Form.Label>
                                <Form.Control
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: Certificación Java SE 6..."
                                    name="name" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona un título
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formCompany">
                                <Form.Label>Empresa que Certificó</Form.Label>
                                <Form.Control
                                    value={company}
                                    onChange={event => setCompany(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: Oracle"
                                    name="company" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una empresa
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formExpeditionDate">
                                <Form.Label>Fecha de Expedición</Form.Label>
                                <Form.Control
                                    value={expeditionDate}
                                    onChange={event => setExpeditionDate(event.target.value)}
                                    type="date"
                                    required
                                    name="expeditionDate" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona la fecha de expedición
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Fecha de Expiración</Form.Label>
                                <Form.Control
                                    value={expirationDate}
                                    onChange={event => setExpirationDate(event.target.value)}
                                    type="date"
                                    required
                                    disabled={!expires}
                                    name="expirationDate" />
                                <Form.Check
                                    type="checkbox"
                                    onChange={_ => setExpires(!expires)}
                                    label="¿Expira?" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona la fecha de expiración
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={!isEditView ? "justify-content-end" : "justify-content-between"}>
                        {isEditView &&
                            <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                                Borrar Certificación
                            </Button>
                        }
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar Certificación"
                    message="¿Realmente deseas borrar este certificación de tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteCertification} />
            }
        </div>
    );
}

const AddCertification = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <CertificationsModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Añadir Certificación"} />
            </Row>
        </div>
    );
}

const EditCertification = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <CertificationsModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Certificación"}
                    index={props.index} />
            </Row>
        </div>
    );
}

const DisplayCertifications = props => {

    const expert = props.expert;
    const [certificationIndex, setCertificationIndex] = useState(-1);

    useEffect(() => {
        props.toggle(true);
    }, [certificationIndex]);

    const expertRows = map(expert?.certifications, (certification, index) => {
        const startYear = new Date(certification.expeditionDate).getFullYear();
        const endYear = new Date(certification.expirationDate).getFullYear();
        const mb = index === expert.certifications.length - 1 ? "" : "mb-3"
        const borderBottom = index === expert.certifications.length - 1 ? "" : "border-bottom";
        const picture = certification.picture;
        return (
            <Row key={index} className={`${mb} ${borderBottom} mx-1 pb-3`}>
                {picture &&
                    <Col xs={3} sm={2} xl={1} className="align-self-center px-0">
                        <Image
                            thumbnail
                            src={picture}
                            style={{ padding: "0.1rem", borderRadius: 0 }}
                        />
                    </Col>
                }
                <Col xs={true} className="align-self-center">
                    <p className={picture && "mb-0"}>
                        <span className="font-weight-bold card-headers">{certification.name}</span><br />
                        <span>{certification.company}</span><br />
                        {!certification.expires ?
                            (<span className="text-gray">
                                <time>{startYear}</time>
                            </span>) :
                            (<span className="text-gray">
                                <time>{startYear}</time> – <time>{endYear}</time>
                            </span>)
                        }
                    </p>
                </Col>
                <Col className="text-right" xs={2}>
                    <Edit
                        titleAccess={"Editar Certificación"}
                        className="primary-color pointer-cursor"
                        onClick={_ => setCertificationIndex(index)}
                    />
                </Col>
            </Row >
        );
    });

    return (
        <div>
            {props.show && certificationIndex !== -1 &&
                <EditCertification
                    toggle={props.toggle}
                    expert={expert}
                    index={certificationIndex} />}
            {expertRows}
        </div>
    );
}

export { AddCertification, EditCertification, DisplayCertifications };
