import React, { useState, useEffect } from 'react';
import '../../../css/colors.scss';
import '../../../css/ExpertProfile.scss';
import ConfirmationDialog from '../../ConfirmationDialog';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import { cloneDeep, map, remove, forEach } from 'lodash';
import { Edit } from '@material-ui/icons';
import updateExpert from '../../../api/expert';

const ArticlesModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const articles = expert?.articles;
    const isEditView = props.index !== undefined;
    const index = isEditView ? props.index : -1;

    const [title, setTitle] = useState(isEditView ? articles[index].title : "");
	const [url, setUrl] = useState(isEditView ? articles[index].url : "");
    const [description, setDescription] = useState(isEditView ? articles[index].description : "");
    const [date, setDate] = useState(isEditView ? articles[index].date.split("T")[0] : "");
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            if (isEditView) updateArticles();
            else addArticle();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => formData.append(key, JSON.stringify(value)))
        return formData;
    }

    const deleteArticle = async _ => {
        remove(expert.articles, (_, i) => { return i === index; });
        await update();
    }

    const updateArticles = async _ => {
        const articles = {
            title: title,
            description: description,
			url: url,
            date: date,
        }
        expert.articles[index] = articles;
        await update();
    }

    const addArticle = async _ => {
        const articles = {
            title: title,
            description: description,
			url: url,
            date: date,
        }
        expert.articles.push(articles);
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formTitle">
                                <Form.Label>Título</Form.Label>
                                <Form.Control
                                    value={title}
                                    onChange={event => setTitle(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: ¿Cómo hacer Marketing Digital?..."
                                    name="title" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona un título
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control
                                    value={description}
                                    onChange={event => setDescription(event.target.value)}
                                    as="textarea"
                                    rows="3"
                                    required
                                    name="description" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una descripción
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
						<Form.Row>
                            <Form.Group as={Col} controlId="formTitle">
                                <Form.Label>URL</Form.Label>
                                <Form.Control
                                    value={url}
                                    onChange={event => setUrl(event.target.value)}
                                    type="URL"
                                    required
                                    placeholder="Ej: https://miarticulo.com"
                                    name="url" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una URL válida
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDate">
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control
                                    value={date}
                                    onChange={event => setDate(event.target.value)}
                                    type="date"
                                    required
                                    name="date" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona la fecha
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={!isEditView ? "justify-content-end" : "justify-content-between"}>
                        {isEditView &&
                            <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                                Borrar Artículo
                            </Button>
                        }
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar Artículo"
                    message="¿Realmente deseas borrar este artículo de tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteArticle} />
            }
        </div>
    );
}

const AddArticle = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <ArticlesModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Añadir Artículo"} />
            </Row>
        </div>
    );
}

const EditArticle = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <ArticlesModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Artículo"}
                    index={props.index} />
            </Row>
        </div>
    );
}

const DisplayArticles = props => {

    const expert = props.expert;
    const [articleIndex, setArticleIndex] = useState(-1);

    useEffect(() => {
        props.toggle(true);
    }, [articleIndex]);

    const expertRows = map(expert?.articles, (article, index) => {
        const year = new Date(article.date).getFullYear();
        const mb = index === expert.articles.length - 1 ? "" : "mb-3"
        const borderBottom = index === expert.articles.length - 1 ? "" : "border-bottom";
        return (
            <Row key={index} className={`${mb} ${borderBottom} mx-1`}>
                <Col xs={9}>
                    <p>
                        <span className="font-weight-bold card-headers">{article.title}</span><br />
                        <span>{article.description}</span><br />
						<span><a target="_blank" href={article.url}>{article.url}</a></span><br />
                        <span className="text-gray"><time>{year}</time></span>
                    </p>
                </Col>
                <Col className="text-right" xs={3}>
                    <Edit
                        titleAccess={"Editar Artículo"}
                        className="primary-color pointer-cursor"
                        onClick={_ => setArticleIndex(index)}
                    />
                </Col>
            </Row>
        );
    });

    return (
        <div>
            {props.show && articleIndex !== -1 &&
                <EditArticle
                    toggle={props.toggle}
                    expert={expert}
                    index={articleIndex} />}
            {expertRows}
        </div>
    );
}

export { AddArticle, EditArticle, DisplayArticles };

