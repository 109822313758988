import React, { useState } from 'react';
import '../../../css/colors.scss';
import { Button, Col, Row, Form, Modal, Image } from 'react-bootstrap';
import { Chip } from '@material-ui/core';
import { cloneDeep, map, forEach } from 'lodash';
import Select from 'react-select'
import updateExpert from '../../../api/expert';
import { getCountries } from '../../../util';
import { AreaSelector } from '../..';

const DisplayBasicInfo = props => {

    const expert = props.expert;
    const expertiseAreas = expert.expertiseAreas;
    const profilePhoto = expert.profilePhoto === "" || expert.profilePhoto === undefined ?
        "https://i1.wp.com/researchictafrica.net/wp/wp-content/uploads/2016/10/default-profile-pic.jpg?ssl=1" :
        expert?.profilePhoto;
    const { name, country } = expert.userData;
    const { type, whatsapp, workWebsite, personalBlog } = expert;

    const displayExpertiseAreas = map(expertiseAreas, area => {
        return (
            <Chip
                color="primary"
                size="small"
                className="mr-1 mt-1 btn-primary-color"
                label={area} />
        );
    });

    return (
        <Col className="align-items-center d-flex flex-column p-0">
            {profilePhoto !== "" && profilePhoto !== undefined &&
                <Image
                    src={profilePhoto}
                    responsive
                    fluid
                    style={{ width: 150, height: 150, border: "1px solid #dedede", marginBottom: 10 }}
                    roundedCircle />
            }
            <p>
                <span className="font-weight-bold">{name.firstName} {name.lastName}</span>
                <span> - {type}</span>
            </p>
            <p className="text-center">
                {country !== "" && <span>País: {country}<br /></span>}
                {whatsapp !== "" && <span>Whatsapp: {whatsapp}<br /></span>}
                {workWebsite !== "" && <span>Página Web Laboral: {workWebsite}<br /></span>}
                {personalBlog !== "" && <span>Whatsapp: {personalBlog}<br /></span>}
            </p>
            <p className="font-italic expert-textarea">{expert?.trajectoryBrief}</p>
            <Row className="justify-content-center">
                {expertiseAreas.length > 0 && displayExpertiseAreas}
            </Row>
        </Col>
    )
}

const BasicInfoModalForm = props => {

    const handleClose = _ => props.toggle(false);
    var expert = cloneDeep(props.expert);
    const [profilePhoto, setProfilePhoto] = useState(expert?.profilePhoto === "" || expert?.profilePhoto === undefined ?
        "https://i1.wp.com/researchictafrica.net/wp/wp-content/uploads/2016/10/default-profile-pic.jpg?ssl=1" :
        expert?.profilePhoto);
    const [firstName, setFirstName] = useState(expert?.userData?.name?.firstName);
    const [lastName, setLastName] = useState(expert?.userData?.name?.lastName);
    const [country, setCountry] = useState(expert?.userData?.country || "");
    const [trajectoryBrief, setTrajectoryBrief] = useState(expert?.trajectoryBrief);
    const [whatsapp, setWhatsapp] = useState(expert?.whatsapp || "");
    const [workWebsite, setWorkWebsite] = useState(expert?.workWebsite || "");
    const [personalBlog, setPersonalBlog] = useState(expert?.personalBlog || "");

    const countryList = getCountries();
    const countriesSelect = map(countryList, country => {
        return { label: country["name_es"], value: country["name_es"] }
    });

    const [selectValues, setSelectValues] = useState(
        map(expert?.expertiseAreas, value => {
            return { label: value, value: value }
        })
    );
    const [validated, setValidated] = useState(false);
    var fileToUpload = React.createRef();
    // HACER la parte de 'PROFESOR DE CERTIFICACION'

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            updateBasicInfo();
        }
        return false;
    }

    const updateBasicInfo = async () => {
        expert = {
            ...expert,
            userData: {
                ...expert.userData,
                name: { firstName: firstName, lastName: lastName },
                country: country
            },
            whatsapp: whatsapp,
            workWebsite: workWebsite,
            personalBlog: personalBlog,
            trajectoryBrief: trajectoryBrief.trim(),
            expertiseAreas: map(selectValues, value => {
                return value.label;
            })
        }

        if (fileToUpload.current.files.length === 1) {
            expert.newProfilePhoto = fileToUpload.current.files[0];
        }

        await update();
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => {
            if (key !== 'newProfilePhoto') {
                formData.append(key, JSON.stringify(value));
            } else {
                formData.append(key, value);
            }
        })
        return formData;
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    const onChangeCountry = value => {
        setCountry(value?.label || "");
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        {profilePhoto !== "" && profilePhoto !== undefined &&
                            <Form.Row>
                                <Image
                                    src={profilePhoto}
                                    responsive
                                    fluid
                                    style={{ width: 150, height: 150, border: "1px solid #dedede" }}
                                    roundedCircle />
                            </Form.Row>
                        }
                        <Form.Row>
                            <Form.Group as={Col} controlId="formProfilePhoto">
                                <Form.Label>Foto de Perfil</Form.Label>
                                <Form.Control
                                    onChange={event => {
                                        if (event.target?.files?.length > 0) {
                                            setProfilePhoto(URL.createObjectURL(event.target.files[0]))
                                        }
                                    }}
                                    type="file"
                                    accept="image/*"
                                    ref={fileToUpload}
                                    name="profilePhoto"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formFirstName">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                    value={firstName}
                                    onChange={event => setFirstName(event.target.value)}
                                    required
                                    type="text"
                                    name="firstName" />
                                <Form.Control.Feedback type="invalid">
                                    Este campo no puede quedar vacío
                                    </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formLastName">
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control
                                    value={lastName}
                                    onChange={event => setLastName(event.target.value)}
                                    required
                                    type="text"
                                    name="lastName" />
                                <Form.Control.Feedback type="invalid">
                                    Este campo no puede quedar vacío
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formCountry">
                                <Form.Label>País</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultInputValue={country}
                                    isClearable
                                    isSearchable
                                    onChange={onChangeCountry}
                                    name="color"
                                    options={countriesSelect}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formWhatsapp">
                                <Form.Label>Whatsapp</Form.Label>
                                <Form.Control
                                    value={whatsapp}
                                    onChange={event => setWhatsapp(event.target.value)}
                                    type="text"
                                    placeholder="Ej: +525512345678"
                                    name="whatsapp" />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formWorkWebsite">
                                <Form.Label>Sitio web laboral</Form.Label>
                                <Form.Control
                                    value={workWebsite}
                                    onChange={event => setWorkWebsite(event.target.value)}
                                    placeholder="Ej: https://geniall.io/"
                                    type="text"
                                    name="workWebsite" />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formPersonalBlog">
                                <Form.Label>Blog personal</Form.Label>
                                <Form.Control
                                    value={personalBlog}
                                    onChange={event => setPersonalBlog(event.target.value)}
                                    placeholder="Ej: https://miblog.com"
                                    type="text"
                                    name="personalBlogName" />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formTrajectoryBrief">
                                <Form.Label>Resumen de trayectoria</Form.Label>
                                <Form.Control
                                    value={trajectoryBrief}
                                    onChange={event => setTrajectoryBrief(event.target.value)}
                                    as="textarea"
                                    maxLength={400}
                                    rows="5"
                                    name="trajectoryBrief" />
                                <div className="d-flex justify-content-end" style={{ color: "gray" }}>{trajectoryBrief?.length}/400</div>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formExpertiseAreas">
                                <Form.Label>Áreas de Expertiz</Form.Label>
                                <AreaSelector value={selectValues} setValues={setSelectValues} />
                            </Form.Group>
                        </Form.Row>
                        {/* <Form.Row>
                            <Form.Group as={Col} controlId="formCertificationAreas">
                                <Form.Label>Profesor de Certificaciones</Form.Label>
                                <Form.Control type="text" name="certificationAreas" />
                            </Form.Group>
                        </Form.Row> */}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-end">
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div >
    );
}

const EditBasicInfo = props => {

    return (
        <div>
            <Row className="justify-content-center">
                <BasicInfoModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Información Básica"} />
            </Row>
        </div>
    );

}

export { EditBasicInfo, DisplayBasicInfo };
