import React, { useState, useEffect } from 'react';
import '../../../css/colors.scss';
import '../../../css/ExpertProfile.scss';
import ConfirmationDialog from '../../ConfirmationDialog';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import { cloneDeep, map, remove, forEach } from 'lodash';
import { Edit } from '@material-ui/icons';
import updateExpert from '../../../api/expert';

const EducationModalForm = props => {

    const handleClose = _ => props.toggle(false);
    const expert = cloneDeep(props.expert);
    const education = expert?.education;
    const isEditView = props.index !== undefined;
    const index = isEditView ? props.index : -1;

    const [university, setUniversity] = useState(isEditView ? education[index].university : "");
    const [obtainedDegree, setObtainedDegree] = useState(isEditView ? education[index].obtainedDegree : "");
    const [startDate, setStartDate] = useState(isEditView ? education[index].startDate.split("T")[0] : "");
    const [endDate, setEndDate] = useState(isEditView ? education[index].endDate.split("T")[0] : "");
    const [validated, setValidated] = useState(false);
    const [confirmationDialog, showConfirmationDialog] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            if (isEditView) updateEducation();
            else addEducation();
        }
        return false;
    }

    const expertToFormData = () => {
        const formData = new FormData();
        forEach(expert, (value, key) => formData.append(key, JSON.stringify(value)))
        return formData;
    }

    const deleteEducation = async _ => {
        remove(expert.education, (_, i) => { return i === index; });
        await update();
    }

    const updateEducation = async _ => {
        const education = {
            university: university,
            obtainedDegree: obtainedDegree,
            startDate: startDate,
            endDate: endDate
        }
        expert.education[index] = education;
        await update();
    }

    const addEducation = async _ => {
        const education = {
            university: university,
            obtainedDegree: obtainedDegree,
            startDate: startDate,
            endDate: endDate
        }
        expert.education.push(education);
        await update();
    }

    const update = async () => {
        const formData = expertToFormData();
        const success = await updateExpert(formData, expert.userData._id);
        if (success) {
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formUniversity">
                                <Form.Label>Universidad</Form.Label>
                                <Form.Control
                                    value={university}
                                    onChange={event => setUniversity(event.target.value)}
                                    type="text"
                                    required
                                    className="text-truncate"
                                    placeholder="Ej: Universidad Nacional Autónoma de México"
                                    name="university" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona una universidad
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDegree">
                                <Form.Label>Título obtenido</Form.Label>
                                <Form.Control
                                    value={obtainedDegree}
                                    onChange={event => setObtainedDegree(event.target.value)}
                                    type="text"
                                    required
                                    placeholder="Ej: Licenciatura, Maestría, ..."
                                    name="degree" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona el título obtenido
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formStartDate">
                                <Form.Label>Fecha de Inicio</Form.Label>
                                <Form.Control
                                    value={startDate}
                                    onChange={event => setStartDate(event.target.value)}
                                    type="date"
                                    required
                                    name="startDate" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona la fecha de inicio
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formEndDate">
                                <Form.Label>Fecha de Término</Form.Label>
                                <Form.Control
                                    value={endDate}
                                    required
                                    onChange={event => setEndDate(event.target.value)}
                                    type="date"
                                    name="endDate" />
                                <Form.Control.Feedback type="invalid">
                                    Proporciona la fecha de término
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className={!isEditView ? "justify-content-end" : "justify-content-between"}>
                        {isEditView &&
                            <Button variant="primary-color" onClick={_ => showConfirmationDialog(true)}>
                                Borrar Elemento
                            </Button>
                        }
                        <Button variant="primary-color" type="submit">
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {confirmationDialog &&
                <ConfirmationDialog
                    title="Eliminar elemento"
                    message="¿Realmente deseas borrar esta opción de educación de tu perfil?"
                    close={_ => showConfirmationDialog(false)}
                    onConfirmDelete={deleteEducation} />
            }
        </div>
    );
}

const AddEducation = props => {

    return (
        <div>
            <Row className="justify-content-center">
                <EducationModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Añadir Educación"} />
            </Row>
        </div>
    );
}

const EditEducation = props => {
    return (
        <div>
            <Row className="justify-content-center">
                <EducationModalForm
                    expert={props.expert}
                    toggle={props.toggle}
                    title={"Editar Educación"}
                    index={props.index} />
            </Row>
        </div>
    );
}

const DisplayEducation = props => {

    const expert = props.expert;
    const [educationIndex, setEducationIndex] = useState(-1);

    useEffect(() => {
        props.toggle(true);
    }, [educationIndex]);

    const expertRows = map(expert?.education, (education, index) => {
        const startYear = new Date(education.startDate).getFullYear();
        const endYear = new Date(education.endDate).getFullYear();
        const mb = index === expert.education.length - 1 ? "" : "mb-3"
        const borderBottom = index === expert.education.length - 1 ? "" : "border-bottom";
        return (
            <Row key={index} className={`${mb} ${borderBottom} mx-1`}>
                <Col xs={9}>
                    <p>
                        <span className="font-weight-bold card-headers">{education.university}</span><br />
                        <span>{education.obtainedDegree}</span><br />
                        <span className="text-gray"><time>{startYear}</time> – <time>{endYear}</time></span>
                    </p>
                </Col>
                <Col className="text-right" xs={3}>
                    <Edit
                        titleAccess={"Editar educación"}
                        className="primary-color pointer-cursor"
                        onClick={_ => setEducationIndex(index)}
                    />
                </Col>
            </Row>
        );
    });

    return (
        <div>
            {props.show && educationIndex !== -1 &&
                <EditEducation
                    toggle={props.toggle}
                    expert={expert}
                    index={educationIndex} />}
            {expertRows}
        </div>
    );
}

export { AddEducation, EditEducation, DisplayEducation };
