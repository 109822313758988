import axios from 'axios';
import config from '../config/index.js';

const api = axios.create({
    baseURL: `${config.server.protocol}://${config.server.host}:${config.server.port}/`,
})

export const registerExpert = userData => api.post('/expert-register', userData);
export const login = credentials => api.post('/login', credentials);
export const getExpert = userID => api.get(`expert/${userID}`);
export const updateExpert = (formData, id) => api.post(`expert/${id}`, formData, { headers: { 'content-type': 'multipart/form-data' } });
export const getCoursesByExpert = expertID => api.get(`course/getAllByExpert?expertID=${expertID}`);

const apis = {
    registerExpert,
    login,
    getExpert,
    updateExpert,
    getCoursesByExpert
}

export default apis;