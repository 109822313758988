import api from './index';

const updateExpert = async (formData, id) => {

    let success = false;
    await api.updateExpert(formData, id)
        .then(res => {
            if (res.data.success) {                
                success = true;
            }
        })
        .catch(error => {
            if (error.response !== undefined) {
                alert(error.response.data.error);
            } else {
                alert("Error al conectarse al servidor");
            }
            success = false;
        })
    
    return success;
        
}

export default updateExpert;