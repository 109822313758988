import React, { useState, useEffect } from 'react';
import '../css/colors.scss';
import '../css/GeniallNavbar.scss';
import { Navbar, Nav } from 'react-bootstrap';
import { MenuRounded } from '@material-ui/icons';

const DashboardNavbar = (props) => {
    const geniallLogoURL = "https://geniall.io/wp-content/uploads/2020/04/geniall-logo.png";

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const brandWidth = windowWidth >= 650 ? "183px" : "123px";
    const brandMarginRight = windowWidth >= 650 ? "mr-3" : "mr-2";
    const brandTextSize = windowWidth >= 650 ? "2rem" : "1.3rem";
    const showMenu = windowWidth < 768;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return (
        <Navbar className="fixed-top" collapseOnSelect expand="md" bg="dark" variant="dark" >
            <Navbar.Brand href="https://geniall.io/">
                <img
                    src={geniallLogoURL}
                    width={brandWidth}
                    height="auto"
                    className={'d-inline-block ' + brandMarginRight}
                    alt="logo geniall"
                />
                <span
                    style={{ 'fontSize': brandTextSize }}
                    className="align-top"
                >
                    {props.type}
                </span>
            </Navbar.Brand>
            {showMenu &&
                <MenuRounded
                    onClick={_ => props.onClickMenu(!props.mobileMenu)}
                    fontSize="large"
                    style={{ color: 'white' }}
                />
            }
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {/* <Nav.Link onClick={_ => props.onClickMenuItem("ACCOUNT")}>Mi cuenta</Nav.Link> */}
                    <Nav.Link onClick={_ => props.onClickMenuItem("LOGOUT")}>Salir</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    );
};

export default DashboardNavbar;