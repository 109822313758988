import React from 'react';
import { GeniallNavbar, Background, Login, Register } from '../components';
import '../css/Login.scss';
import '../css/colors.scss';
import { Container, Jumbotron, Col } from 'react-bootstrap';
import backgroundImage from '../media/trama-1024x649.png';
import ExpertRegister from '../components/expert/ExpertRegister.js';

const LandingPage = props => {
    const LOGIN = "/login";
    const REGISTER = "/registro";
    const EXPERT_REGISTER = "/registro/registro-experto";
    const url = props.location.pathname;
    return (
        <Container fluid className="px-1">
            <Background url={backgroundImage} />
            <GeniallNavbar />
            <div style={{ marginTop: "6rem" }}>
                {url === LOGIN && <Login />}
                {url === REGISTER && <Register />}
                {url === EXPERT_REGISTER && <ExpertRegister />}
                {url !== LOGIN && url !== REGISTER && url !== EXPERT_REGISTER &&
                    <Jumbotron className="col-md-6 mx-auto mt-auto text-center">
                        <Container>
                            <h1>Página no encontrada</h1>
                            <Col className="mt-5">
                                <span><h5>La página solicitada no fue encontrada.</h5></span>
                                <span><h5>Verificar que escribió bien la dirección y volver a intentar.</h5></span>
                            </Col>
                        </Container>
                    </Jumbotron>
                }
            </div>

        </Container >
    );
}

export default LandingPage;
