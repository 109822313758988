const getExpertiseAreas = () => {
    return areaList;
}

const areaList = [
    'Digital Marketing',
    'Digital Analytics',
    'Machine Learning',
    'Inteligencia Artificial',
    'Digital Transformation',
    'Market Analytics',
    'E-Commerce',
    'Social Selling',
    'CRM',
    'Social Media',
    'Marketing',
    'Marketing Strategy',
    'Life Sciences',
    'Change Management',
    'Business Intelligence',
    'Digital Measurement',
    'Conversion Rate Optimization',
    'Data Visualization'
];

export default getExpertiseAreas;